import React from "react";
import { MessageCircle, ChevronRight } from "lucide-react";
import UserResponseCard from "../UserResponseCard";

const ResponsesList = ({
  selectedDate,
  groupedResponses,
  activeComment,
  setActiveComment,
  comments,
  setComments,
  handleCommentSubmit,
  handleResponseClick,
}) => {
  if (!selectedDate) return null;

  return (
    <div className="space-y-3 lg:col-span-4">
      <div className="rounded-lg bg-gradient-to-b from-slate-800 to-slate-950">
        {/* Header Section */}
        <div className="p-4 bg-gradient-to-b from-slate-800 to bg-neutral-950 rounded-lg border-b border-slate-900">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="h-[1px] w-4 bg-gradient-to-r from-neutral-500/40 to-transparent" />
              <div className="flex items-center space-x-2">
                <MessageCircle
                  className="w-4 h-4 text-neutral-200"
                  strokeWidth={1.5}
                />
                <span className="text-slate-300 text-xs uppercase tracking-wider">
                  Responses for {new Date(selectedDate).toLocaleDateString()}
                </span>
              </div>
            </div>
            <ChevronRight
              className="w-4 h-4 text-neutral-100"
              strokeWidth={1.5}
            />
          </div>
        </div>

        {/* Responses Grid */}
        <div className="p-4 md:p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {groupedResponses[selectedDate]?.map((response) => (
              <UserResponseCard
                key={`${response.user_id}-${response.date_answered}`}
                response={response}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                comments={comments}
                setComments={setComments}
                handleCommentSubmit={handleCommentSubmit}
                handleResponseClick={handleResponseClick}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsesList;
