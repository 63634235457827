import React from "react";
import MyResponses from "./VibeCheck/MyResponses";
import ViewUserResponses from "./VibeCheck/ViewUserResponses";
import { MessageCircle, ChartBar, ChevronRight } from "lucide-react";

const Responses = ({
  responses,
  setResponses,
  businessName,
  currentUsername,
}) => {
  return (
    <div className="relative bg-gradient-to-b from-neutral-300 to-neutral-400  mt-4 rounded-md border border-neutral-700 shadow-2xl">
      <div className="max-w-[1400px] mx-auto px-0.1 sm:px-6">
        <div className="p-2">
          {/* Header */}
          <div className="bg-gradient-to-b from-slate-800 to bg-neutral-950  rounded w-full justify-center flex items-center space-x-3 mb-8 shadow-2xl">
            <div className="w-8 bg-gradient-to-r from-neutral-800 to-transparent" />
            <h1 className="text-neutral-300 font-semibold font-serif mt-2 mb-2 text-lg tracking-widest">
              Vibe-Checks
            </h1>
            <img
              src="/assets/SVG/SeeYouSoon.svg"
              alt="high five"
              className="w-[35px] h-1/4"
            />
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            {/* My Responses Section */}
            <div className="col-span-1 lg:col-span-12">
              <div className="rounded-lg shadow-2xl">
                <div className="bg-gradient-to-b from-[#4f5b32] to bg-neutral-950 rounded-md p-3 border-b border-[#4F5B32]">
                  <div className="flex items-center justify-between">
                    <h2 className="text-slate-200 text-xs uppercase tracking-widest flex items-center gap-2">
                      <MessageCircle className="w-4 h-4 text-neutral-300" />
                      My Responses
                    </h2>
                    <ChevronRight className="w-4 h-4 neutral-200" />
                  </div>
                </div>
                <div className="p-1">
                  <div className=" rounded-lg">
                    <div className="p-4 shadow-2xl">
                      <MyResponses
                        responses={responses}
                        username={currentUsername}
                        businessName={businessName}
                        setResponses={setResponses}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* View User Responses Section */}
            <div className="col-span-1 lg:col-span-12">
              <div className="rounded-lg shadow-2xl">
                <div className="bg-gradient-to-b from-[#4f5b32] to bg-neutral-950 p-3 rounded-lg border-b border-[#4F5B32]">
                  <div className="flex items-center justify-between">
                    <h2 className="text-slate-200 text-xs uppercase tracking-widest flex items-center gap-2">
                      <ChartBar className="w-4 h-4 text-[#8B9D6B]" />
                      View User Responses
                    </h2>
                    <ChevronRight className="w-4 h-4 text-[#8B9D6B]" />
                  </div>
                </div>
                <div className="p-1">
                  <div className="rounded-lg">
                    <div className="p-2 mt-3">
                      <ViewUserResponses
                        responses={responses}
                        setResponses={setResponses}
                        businessName={businessName}
                        currentUsername={currentUsername}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Custom Scrollbar Styling */}
          <style jsx>{`
            .custom-scrollbar::-webkit-scrollbar {
              width: 6px;
            }
            .custom-scrollbar::-webkit-scrollbar-track {
              background: #1e293b;
              border-radius: 4px;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb {
              background: #334155;
              border-radius: 4px;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:hover {
              background: #475569;
            }
          `}</style>
        </div>
      </div>
    </div>
  );
};

export default Responses;
