import React, { useState, useEffect } from "react";
import axios from "axios";
import { Users, Share2, Clock } from "lucide-react";

const TeamReviewPage = ({ businessName, onReviewSelect }) => {
  const [pendingReviews, setPendingReviews] = useState([]);
  const [completedReviews, setCompletedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const [reviewsResponse, teamMembersResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/team-reviews`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`/api/v1/businesses/${businessName}/team-members`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setPendingReviews(reviewsResponse.data.pending_reviews || []);
      setCompletedReviews(reviewsResponse.data.completed_reviews || []);
      setTeamMembers(teamMembersResponse.data || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch reviews");
      setLoading(false);
    }
  };

  const handleShare = async (review, e) => {
    e.stopPropagation(); // Prevent triggering the card click
    try {
      const username = extractUsername(review.reviews_text);
      const teamMember = teamMembers.find((m) => m.username === username);

      if (!teamMember) {
        alert("Could not find the team member to share with.");
        return;
      }

      const shareData = {
        shared_with: teamMember.id,
      };

      const token = localStorage.getItem("access_token");
      await axios.post(
        `/api/v1/businesses/${businessName}/record-review-click/${review.id}`,
        shareData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      alert(`Review successfully shared with ${username}!`);
      fetchData();
    } catch (error) {
      console.error("Error sharing review:", error);
      const errorMessage =
        error.response?.data?.detail ||
        "Failed to share review. Please try again.";
      alert(errorMessage);
    }
  };

  const extractUsername = (reviewText) => {
    const match = reviewText.match(/Review for team member: ([^\n]+)/);
    if (!match) {
      const oldFormatMatch = reviewText.match(/Review for ([^:]+):/);
      return oldFormatMatch ? oldFormatMatch[1].trim() : null;
    }
    return match[1].trim();
  };

  useEffect(() => {
    fetchData();
  }, [businessName]);

  if (loading)
    return <div className="text-gray-400">Loading team reviews...</div>;
  if (error) return <div className="text-red-400">{error}</div>;

  return (
    <div className="space-y-4">
      {/* Pending Reviews Section */}
      <div className="space-y-2">
        <h3 className="text-md font-medium text-neutral-900 mt-4 mb-3">
          Pending Reviews
        </h3>
        {pendingReviews.length === 0 ? (
          <p className="text-gray-500 text-sm">No pending team reviews</p>
        ) : (
          pendingReviews.map((review) => (
            <div
              key={review.id}
              className="p-4 bg-slate-800 rounded-lg border border-neutral-700 cursor-pointer hover:border-yellow-500/50 transition-colors"
              onClick={() => onReviewSelect(review)}
            >
              <p className="text-sm text-neutral-200">{review.reviews_text}</p>
              <div className="mt-2 flex items-center text-xs text-gray-500">
                <Clock className="w-3 h-3 mr-1" />
                Expires: {new Date(review.expiration_date).toLocaleDateString()}
              </div>
            </div>
          ))
        )}
      </div>

      {/* Completed Reviews Section */}
      <div className="space-y-2">
        <h3 className="text-md font-medium text-neutral-900 mt-5 mb-3">
          Completed Reviews
        </h3>
        {completedReviews.length === 0 ? (
          <p className="text-gray-500 text-sm">No completed team reviews</p>
        ) : (
          completedReviews.map((review) => {
            const username = extractUsername(review.reviews_text);
            const teamMember = teamMembers.find((m) => m.username === username);
            const isTeamLeader = teamMembers.some((m) => m.is_leader);

            return (
              <div
                key={review.id}
                className="p-4 bg-slate-700 rounded-lg border border-slate-700 cursor-pointer hover:border-slate-600 transition-colors"
                onClick={() =>
                  setSelectedReview(
                    selectedReview?.id === review.id ? null : review,
                  )
                }
              >
                {/* Card Header */}
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-sm text-gray-300">
                      {review.reviews_text}
                    </p>
                    <div className="mt-2 flex items-center text-xs text-gray-500">
                      <Clock className="w-3 h-3 mr-1" />
                      Completed on:{" "}
                      {new Date(review.expiration_date).toLocaleDateString()}
                    </div>
                  </div>

                  {/* Share Button */}
                  {teamMember && !review.is_shared && isTeamLeader && (
                    <button
                      onClick={(e) => handleShare(review, e)}
                      className="ml-4 flex items-center gap-2 px-3 py-2 text-neutral-900 bg-neutral-300 hover:bg-indigo-700 rounded-lg text-xs"
                    >
                      <Share2 className="w-4 h-4" />
                      Share with {teamMember.username}
                    </button>
                  )}
                </div>

                {/* Expanded Content */}
                {selectedReview?.id === review.id && (
                  <div className="mt-4 space-y-2 border-t border-slate-700 pt-4">
                    <div className="grid grid-cols-1 gap-4">
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => {
                        const question = review[`question_${num}`];
                        const answer = review[`answer_${num}`];
                        if (question && answer) {
                          return (
                            <div key={num} className="text-sm">
                              <p className="text-gray-400">{question}</p>
                              <p className="text-gray-300 ml-4">{answer}</p>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default TeamReviewPage;
