import React, { useState } from "react";

const QuestionSubmissionsList = ({ submissions, title, columns = 1 }) => (
  <div>
    <h5 className="text-sm font-medium text-neutral-300 mb-3">{title}</h5>
    <div className={`grid grid-cols-${columns} gap-2`}>
      {submissions.map((submission, idx) => (
        <div key={idx} className="p-2 bg-slate-700">
          <div className="flex justify-between items-center">
            <span className="text-white text-xs">{submission.username}</span>
            {submission.submitted_at && (
              <span className="text-xs text-gray-400">
                {new Date(submission.submitted_at).toLocaleDateString()}
              </span>
            )}
          </div>
          {submission.answer && (
            <p className="text-xs text-gray-400 mt-2">
              Vibe Check Answer: {submission.answer}
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
);

const QuestionStats = ({
  questionStats,
  questionStatsLoading,
  questionStatsError,
}) => {
  const [showPending, setShowPending] = useState(false);

  if (questionStatsLoading) {
    return (
      <div className="mt-6 bg-gradient-to-b from-slate-800 to-[#0a0a0a] border border-slate-800 p-4 rounded-lg">
        <h4 className="text-xl mb-4 text-amber-500">Vibe Check Submissions!</h4>
        <p className="text-gray-400">Loading question statistics...</p>
      </div>
    );
  }

  if (questionStatsError) {
    return (
      <div className="mt-6 bg-gradient-to-b from-slate-800 to-[#0a0a0a] border border-slate-800 p-4">
        <h4 className="text-lg mb-4 text-yellow-500">Vibe Check Submissions</h4>
        <p className="text-red-500">{questionStatsError}</p>
      </div>
    );
  }

  if (!questionStats) return null;

  return (
    <div className="mt-6 bg-gradient-to-t from-slate-800 to-slate-950 border border-slate-800 p-4">
      <h4 className="text-md mb-4 text-neutral-200 tracking-wider">
        Vibe Check Submissions
      </h4>
      <div className="space-y-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <div className="p-4 bg-gradient-to-b from-neutral-300 to-neutral-600 border border-neutral-600">
            <p className="text-sm text-neutral-800 font-semibold">
              Total Active Users
            </p>
            <p className="text-md text-white mt-1">
              {questionStats.total_active_users}
            </p>
          </div>
          <div className="p-4 bg-gradient-to-b from-neutral-300 to-neutral-600 border border-neutral-600">
            <p className="text-sm text-neutral-800 font-semibold">
              Submissions This Week
            </p>
            <p className="text-sm text-white mt-1">
              {questionStats.submissions_this_week}
            </p>
          </div>
          <div className="p-4 bg-gradient-to-b from-neutral-300 to-neutral-600 border border-slate-600">
            <p className="text-sm text-neutral-800 font-semibold">
              Submission Rate
            </p>
            <p className="text-md text-white mt-1">
              {questionStats.submission_rate}%
            </p>
          </div>
        </div>
        <div className="text-sm text-neutral-300 mb-4">
          Week Range:{" "}
          {new Date(questionStats.week_range.start).toLocaleDateString()} -{" "}
          {new Date(questionStats.week_range.end).toLocaleDateString()}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className={showPending ? "col-span-1" : "md:col-span-2"}>
            <QuestionSubmissionsList
              title="Recent Submissions"
              submissions={questionStats.recent_submissions || []}
              columns={showPending ? 1 : 2}
            />
          </div>
          {showPending && (
            <div>
              <div className="flex justify-between items-center mb-3">
                <h5 className="text-sm font-medium text-neutral-300">
                  Pending Submissions (
                  {questionStats.pending_submissions?.length || 0})
                </h5>
                <button
                  onClick={() => setShowPending(false)}
                  className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
                >
                  Hide
                </button>
              </div>
              <div className="space-y-2">
                {questionStats.pending_submissions?.map((submission, idx) => (
                  <div key={idx} className="p-2 bg-slate-700">
                    <span className="text-white text-xs">
                      {submission.username}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!showPending && (
            <div className="flex justify-end">
              <button
                onClick={() => setShowPending(true)}
                className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
              >
                Show All Pending (
                {questionStats.pending_submissions?.length || 0})
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionStats;
