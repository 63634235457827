import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import SharedWithMe from "../Shared/SharedWithMe";

const ViewAnsweredReviews = ({ businessName }) => {
  const [answeredReviews, setAnsweredReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [showUserDropdown, setShowUserDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    fetchAnsweredReviews();
    fetchUsers();
  }, [businessName]);

  const fetchAnsweredReviews = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/my-pushed-reviews`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAnsweredReviews([
        ...response.data.active_reviews,
        ...response.data.expired_reviews,
      ]);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching answered reviews:", err);
      setError("Failed to fetch answered reviews. Please try again.");
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setUsers(response.data);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  const handleRecordClick = async (reviewId) => {
    if (!selectedUser) {
      alert("Please select a user to share with.");
      return;
    }
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/record-review-click/${reviewId}`,
        { shared_with: parseInt(selectedUser) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Click recorded successfully");
      setShowUserDropdown(null);
      setSelectedUser("");
    } catch (err) {
      console.error("Error recording review click:", err);
    }
  };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  if (loading) return <div>Loading answered reviews...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div>
      <div className="bg-neutral-900 rounded-lg shadow-2xl p-2">
        <h3 className="text-3xl text-right font-semibold mb-5 text-indigo-400 font-serif">
          my answered reviews
        </h3>
        {answeredReviews.length === 0 ? (
          <p>You haven't answered any reviews yet.</p>
        ) : (
          <div className="space-y-2">
            {answeredReviews.map((review) => (
              <div
                key={review.id}
                className="bg-neutral-800 p-3 rounded-lg shadow-2xl transition-all duration-300 ease-in-out"
              >
                <div className="flex justify-between items-start">
                  <div className="w-3/4">
                    <h4 className="text-md font-semibold font-serif mb-1 text-slate-300">
                      {review.reviews_text}
                    </h4>
                    <p className="text-xs text-gray-400 mb-1">
                      answered on:{" "}
                      {new Date(review.expiration_date).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <Link
                      to={`/reviews/${review.id}`}
                      className="px-2 py-2 bg-indigo-900 text-xs text-white rounded hover:bg-indigo-700"
                    >
                      view answers
                    </Link>
                    <button
                      onClick={() => setShowUserDropdown(review.id)}
                      className="px-2 py-2 text-xs bg-slate-200 text-slate-900 rounded hover:bg-teal-700"
                    >
                      share review
                    </button>
                  </div>
                </div>
                {showUserDropdown === review.id && (
                  <div className="mt-4">
                    <select
                      onChange={(e) => handleUserSelect(e.target.value)}
                      value={selectedUser}
                      className="w-full p-2 bg-gray-700 text-white rounded mb-2"
                    >
                      <option value="">Select a user to share with</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleRecordClick(review.id)}
                      className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <Link
        to="/shared-reviews"
        className="block mt-4 text-center px-4 py-2 bg-purple-900 text-white rounded hover:bg-purple-700"
      >
        View Shared Reviews
      </Link>
    </div>
  );
};

export default ViewAnsweredReviews;
