import React from "react";

const ReviewSubmissionsLists = ({
  recentSubmissions = [],
  pendingSubmissions = [],
}) => {
  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <h5 className="text-sm font-medium text-gray-400 mb-3">
          Recent Submissions
        </h5>
        <div className="space-y-2">
          {recentSubmissions?.map((submission, idx) => (
            <div
              key={idx}
              className="p-2 bg-gray-700 flex justify-between items-center"
            >
              <span className="text-slate-100 text-xs">
                {submission.username}
              </span>
              <span className="text-sm text-gray-400">
                {new Date(submission.submitted_at).toLocaleDateString()}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h5 className="text-sm font-medium text-gray-400 mb-3">
          Pending Submissions
        </h5>
        <div className="space-y-2 max-h-48 overflow-y-auto">
          {pendingSubmissions?.map((user, idx) => (
            <div key={idx} className="p-1 bg-gray-700">
              <span className="text-slate-200 text-xs">{user.username}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewSubmissionsLists;
