import React from "react";

const ScoreGrid = ({ analytics, averageScore, lastCalculated }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="bg-gradient-to-b from-neutral-600 to-[#0a0a0a] border border-slate-800 p-4">
        <h4 className="text-md font-semibold mb-2 text-slate-200">
          Yearly eNPS Score
        </h4>
        <p className="text-md text-white">
          {analytics.enps_score !== null
            ? analytics.enps_score.toFixed(2)
            : "N/A"}
        </p>
      </div>
      <div className="bg-gradient-to-b from-neutral-600 to-[#0a0a0a] border border-slate-800 p-4">
        <h4 className="text-md font-semibold mb-2 text-slate-200">
          Vibe Check Avg
        </h4>
        <p className="text-md text-white">
          {averageScore !== null ? averageScore.toFixed(2) : "N/A"}
        </p>
        {lastCalculated && (
          <p className="text-sm text-gray-400 mt-1">
            Last updated: {new Date(lastCalculated).toLocaleDateString()}
          </p>
        )}
      </div>
    </div>
  );
};

export default ScoreGrid;
