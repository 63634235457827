import React, { useState } from "react";
import ReviewSubmissionsLists from "./ReviewSubmissionLists";

const ReviewStats = ({ reviewStats, reviewStatsLoading, reviewStatsError }) => {
  const [selectedReview, setSelectedReview] = useState(null);

  if (reviewStatsLoading)
    return (
      <div className="mt-6 bg-gray-800 p-4">
        <p className="text-gray-400">Loading review statistics...</p>
      </div>
    );
  if (reviewStatsError)
    return (
      <div className="mt-6 bg-gray-800 p-4">
        <p className="text-red-500">{reviewStatsError}</p>
      </div>
    );
  if (!reviewStats?.submissions_data) return null;

  const filterPendingSubmissionsByTeam = (reviewData) => {
    if (reviewData.is_team_review && reviewData.team_members) {
      return reviewData.pending_submissions.filter((submission) =>
        reviewData.team_members.includes(submission.user_id),
      );
    }
    return reviewData.pending_submissions;
  };

  return (
    <div className="mt-6 bg-gradient-to-b from-slate-800 to-[#0a0a0a] border border-slate-800 p-4">
      <h4 className="text-md mb-4 text-slate-100 tracking-wider">
        Review Submissions
      </h4>
      <div className="space-y-4">
        {reviewStats.submissions_data.map((reviewData, index) => (
          <div
            key={index}
            className="border border-neutral-700 overflow-hidden"
          >
            <button
              onClick={() =>
                setSelectedReview(selectedReview === index ? null : index)
              }
              className="w-full p-4 text-left bg-slate-800 hover:bg-slate-700 transition-colors duration-200"
            >
              <h5 className="text-sm text-slate-100 flex items-center justify-between">
                {reviewData.review_text}
                <span className="text-xs text-slate-400">
                  {selectedReview === index ? "▼" : "▶"}
                </span>
              </h5>
            </button>

            {selectedReview === index && (
              <div className="p-4 space-y-6 bg-slate-900">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="p-4 bg-gradient-to-br from-neutral-300 to-neutral-500 border border-neutral-400">
                    <p className="text-sm text-neutral-800 mb-1">
                      Total Active Users
                    </p>
                    <p className="text-md text-neutral-950">
                      {reviewData.total_active_users}
                    </p>
                  </div>
                  <div className="p-4 bg-gradient-to-br from-neutral-300 to-neutral-500 border border-neutral-400">
                    <p className="text-sm text-slate-800 mb-1">Submissions</p>
                    <p className="text-md text-slate-900">
                      {reviewData.submissions_this_week}
                    </p>
                  </div>
                  <div className="p-4 bg-gradient-to-br from-neutral-300 to-neutral-500 border border-neutral-400">
                    <p className="text-sm text-slate-800 mb-1">
                      Submission Rate
                    </p>
                    <p className="text-md text-neutral-900">
                      {reviewData.submission_rate}%
                    </p>
                  </div>
                </div>
                <ReviewSubmissionsLists
                  recentSubmissions={reviewData.recent_submissions}
                  pendingSubmissions={filterPendingSubmissionsByTeam(
                    reviewData,
                  )}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewStats;
