import React, { useState, useEffect } from "react";
import axios from "axios";
import SentimentAnalysis from "./analytics/SentimentAnalysis";
import ScoreGrid from "./analytics/ScoreGrid";
import ReviewStats from "./analytics/ReviewStats";
import QuestionStats from "./analytics/QuestionStats";
import ENPSStats from "./ENPS/ENPSStats";

const Analytics = ({ businessName }) => {
  // Analytics states
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Average Score states
  const [averageScore, setAverageScore] = useState(null);
  const [lastCalculated, setLastCalculated] = useState(null);
  const [averageScoreError, setAverageScoreError] = useState(null);

  // Review stats states
  const [reviewStats, setReviewStats] = useState(null);
  const [reviewStatsLoading, setReviewStatsLoading] = useState(true);
  const [reviewStatsError, setReviewStatsError] = useState(null);

  // Question stats states
  const [questionStats, setQuestionStats] = useState(null);
  const [questionStatsLoading, setQuestionStatsLoading] = useState(true);
  const [questionStatsError, setQuestionStatsError] = useState(null);

  useEffect(() => {
    fetchAnalytics();
    fetchAverageScore();
    fetchReviewStats();
    fetchQuestionStats();
  }, [businessName]);

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/analytics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAnalytics(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching analytics:", err);
      setError("Failed to fetch analytics. Please try again.");
      setLoading(false);
    }
  };

  const fetchAverageScore = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/average-vc-score`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setAverageScore(response.data.average_score);
      setLastCalculated(response.data.last_calculated);
    } catch (err) {
      console.error("Error fetching average score:", err);
      setAverageScoreError("Failed to fetch average score. Please try again.");
    }
  };

  const fetchReviewStats = async () => {
    setReviewStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/review-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setReviewStats(response.data);
      setReviewStatsLoading(false);
    } catch (err) {
      console.error("Error fetching review stats:", err);
      setReviewStatsError("Failed to fetch review statistics.");
      setReviewStatsLoading(false);
    }
  };

  const fetchQuestionStats = async () => {
    setQuestionStatsLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/question-stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestionStats(response.data);
      setQuestionStatsLoading(false);
    } catch (err) {
      console.error("Error fetching question stats:", err);
      setQuestionStatsError("Failed to fetch question statistics.");
      setQuestionStatsLoading(false);
    }
  };

  if (loading) return <div>Loading analytics...</div>;
  if (error) return <div className="text-red-300">{error}</div>;

  return (
    <div className="bg-slate-800 shadow-2xl p-6 justify-center mt-6">
      <h3 className="bg-gradient-to-b from-slate-800 to-neutral-950 text-md p-2 tracking-widest mb-6 text-neutral-200 text-center border border-slate-900 ">
        Analytics
      </h3>

      <ENPSStats businessName={businessName} />

      <ScoreGrid
        analytics={analytics}
        averageScore={averageScore}
        lastCalculated={lastCalculated}
      />
      <QuestionStats
        questionStats={questionStats}
        questionStatsLoading={questionStatsLoading}
        questionStatsError={questionStatsError}
      />

      <ReviewStats
        reviewStats={reviewStats}
        reviewStatsLoading={reviewStatsLoading}
        reviewStatsError={reviewStatsError}
      />

      <SentimentAnalysis businessName={businessName} />
    </div>
  );
};

export default Analytics;
