import React from "react";
import { Link } from "react-router-dom";
import {
  Users,
  HelpCircle,
  Star,
  BarChart,
  Shield,
  UserCircle,
  UserPlus,
  Home,
  TrendingUp,
  MessageSquare,
  SettingsIcon,
} from "lucide-react";

const Sidebar = ({
  activeSection,
  setActiveSection,
  isAdmin,
  isOpen,
  toggleSidebar,
  user,
}) => {
  if (!user) return null;

  const hasAnalyticsAccess = user.is_admin || user.is_verified;
  const sections = [
    { name: "Dashboard", icon: Home, path: "/dashboard" },
    { name: "Vibe Checks", icon: MessageSquare, path: "/vibe-checks" },
    { name: "Reviews", icon: BarChart, path: "/my-reviews" },
    { name: "One-on-One", icon: UserPlus, path: "/one-on-one" },
  ];

  if (hasAnalyticsAccess) {
    sections.push({ name: "Analytics", icon: BarChart, path: "/analytics" });
  }

  if (isAdmin) {
    sections.push(
      { name: "eNPS", icon: TrendingUp, path: "/enps" },
      { name: "Users", icon: UserCircle, path: "/users" },
      { name: "Create Reviews", icon: Star, path: "/create-reviews" },
      { name: "Questions", icon: HelpCircle, path: "/questions" },
      { name: "User Permissions", icon: Shield, path: "/user-permissions" },
      { name: "Settings", icon: SettingsIcon, path: "/settings" },
    );
  }

  return (
    <div
      className={`bg-gradient-to-b from-neutral-300 to-neutral-400 text-xs tracking-widest text-neutral-900 py-12 font-sans w-64 md:w-60 fixed inset-y-0 left-0 transform transition-transform duration-200 ease-in-out z-50 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:relative md:translate-x-0`}
    >
      <div className="h-full flex flex-col py-7 px-2">
        {/* Navigation links */}
        <div className="flex-grow space-y-4">
          {sections.map((section) => (
            <Link
              key={section.name}
              to={section.path}
              className={`flex items-center tracking-widest space-x-2 py-2.5 px-4 rounded transition duration-200 ${
                activeSection === section.name
                  ? "bg-gradient-to-b from-neutral-600 to bg-neutral-950 text-slate-200 shadow-2xl"
                  : "hover:bg-neutral-100 hover:text-neutral-950 hover:font-semibold"
              }`}
              onClick={() => {
                setActiveSection(section.name);
                toggleSidebar();
              }}
            >
              <section.icon className="h-5 w-5" />
              <span>{section.name}</span>
            </Link>
          ))}
        </div>

        {/* Image at bottom */}
        <div className="mt-8">
          <img
            src="/image1.svg"
            alt="chubs character"
            className="w-full h-32 object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
