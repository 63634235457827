import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/apiConfig";

const ENPSSurvey = () => {
  const { businessName, surveyToken } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [sliderValue, setSliderValue] = useState(3);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/businesses/${businessName}/enps-survey/${surveyToken}`,
        );
        setQuestions(response.data.questions);
        setAlreadyAnswered(response.data.already_answered || false);
        setLoading(false);
      } catch (err) {
        console.error(
          "Error fetching survey:",
          err.response?.data || err.message,
        );
        setError("Failed to load survey questions. Please try again later.");
        setLoading(false);
      }
    };
    fetchSurvey();
  }, [businessName, surveyToken]);

  const handleInputChange = (questionIndex, value) => {
    if (questionIndex === 0) {
      setSliderValue(value);
    }
    setAnswers((prev) => ({ ...prev, [`q${questionIndex + 1}`]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${API_URL}/api/v1/businesses/${businessName}/enps-survey/${surveyToken}/submit`,
        { answers },
      );
      setSubmitted(true);
    } catch (err) {
      setError("Failed to submit survey. Please try again.");
    }
  };

  const renderContent = () => {
    if (loading) return <p className="text-white">Loading survey...</p>;
    if (error) return <p className="text-red-500">{error}</p>;
    if (submitted)
      return (
        <p className="text-neutral-300">Thank you for completing the survey!</p>
      );
    if (alreadyAnswered)
      return (
        <p className="text-neutral-300 font-semibold">
          This survey has already been answered. Thank you for your
          participation!
        </p>
      );

    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        {questions.map((question, index) => (
          <div key={index}>
            <label className="block text-sm font-serif font-medium text-gray-300 mb-1">
              {question}
              {index === 4 && (
                <span className="text-gray-400 text-xs ml-2">(Optional)</span>
              )}
            </label>
            {index === 0 ? (
              // Slider for first question
              <div className="mt-2 space-y-2">
                <input
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={sliderValue}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="w-full h-2 bg-neutral-300 rounded appearance-none cursor-pointer"
                  required
                />
                <div className="flex justify-between px-1 text-xs text-gray-300">
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                  <span>10</span>
                </div>
                <div className="text-center text-gray-300 text-sm">
                  Selected: {sliderValue}
                </div>
              </div>
            ) : (
              <input
                type="text"
                value={answers[`q${index + 1}`] || ""}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="w-full p-2 bg-neutral-300 text-neutral-800 rounded border border-gray-600 focus:ring-2 focus:ring-slate-500 focus:border-indigo-500"
                required={index !== 4}
              />
            )}
          </div>
        ))}
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-neutral-100 text-slate-800 text-xs mt-8 hover:bg-neutral-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="min-h-screen bg-neutral-900 flex flex-col">
      <header className="bg-gradient-to-b from-neutral-200 to-neutral-300 py-4 border border-slate-500 px-6 flex justify-between items-center">
        <h1 className="text-3xl font-serif text-slate-900">{businessName}</h1>
        <span className="text-4xl text-[#4f5b32] font-semibold mr-10 font-serif">
          matcha
        </span>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="bg-gradient-to-bl from-slate-700 to-neutral-900 border border-slate-800 shadow-2xl p-6">
            <h2 className="text-lg font-serif text-[#4f8b32] mb-6">Survey</h2>
            {renderContent()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ENPSSurvey;
