import React, { useState, useEffect } from "react";
import axios from "axios";

import PriorityCheck from "./PriorityCheck";
import QuestionForm from "./QuestionForm";

const CurrentQuestions = ({ businessName }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [sliderValue, setSliderValue] = useState(3);
  const [sliderConfirmed, setSliderConfirmed] = useState(false);
  const [priority, setPriority] = useState("");
  const [priorityChecked, setPriorityChecked] = useState(false);
  const [submittingPriority, setSubmittingPriority] = useState(false);
  const [prioritySubmitError, setPrioritySubmitError] = useState(null);
  const [uncompletedPriorities, setUncompletedPriorities] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, [businessName]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/current-questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setQuestions(response.data.questions);
      setAlreadyAnswered(response.data.already_answered);
      setPriority(response.data.last_answer || "No priority set yet");
      setPriorityChecked(response.data.priority_checked);
      setUncompletedPriorities(response.data.uncompleted_priorities); // Add this line
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch questions. Please try again.");
      setLoading(false);
    }
  };

  const handlePrioritySubmit = async (params) => {
    setSubmittingPriority(true);
    setPrioritySubmitError(null);

    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/priority-check/complete`,
        params || {}, // If no params, send empty object for current user's priority
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      if (!params) {
        // If it's the current user's priority
        setPriorityChecked(true);
      }
      fetchQuestions(); // Refresh all priorities
    } catch (err) {
      console.error("Failed to update priority check:", err);
      setPrioritySubmitError("Failed to update priority. Please try again.");
    } finally {
      setSubmittingPriority(false);
    }
  };
  const handleInputChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSliderConfirm = () => {
    setAnswers((prev) => ({
      ...prev,
      [questions[0].id]: sliderValue.toString(),
    }));
    setSliderConfirmed(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sliderConfirmed) {
      setSubmitError("Please confirm your response for the first question.");
      return;
    }
    setSubmitting(true);
    setSubmitError(null);

    try {
      const formattedAnswers = questions.map((question, index) => ({
        question_id: question.id,
        answer: answers[question.id] || "",
        column_suffix: index === 0 ? "" : `_${index + 1}`,
      }));

      await axios.post(
        `/api/v1/businesses/${businessName}/answer-questions`,
        { answers: formattedAnswers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );

      setAlreadyAnswered(true);
    } catch (err) {
      setSubmitError("Failed to submit answers. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <div>Loading questions...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="bg-gradient-to-b from-neutral-300 to-neutral-400 rounded-lg shadow-2xl p-6 mb-5 border border-neutral-800 mr-4">
      <div className="flex ml-1">
        <h3 className="text-4xl font-semibold mb-2 text-[#4f5b32] font-serif">
          vibe check
        </h3>
        <img
          src="/assets/SVG/SeeYouSoon.svg"
          alt="high five image"
          className="w-[40px] h-1/4"
        />
      </div>

      <PriorityCheck
        priority={priority}
        priorityChecked={priorityChecked}
        submittingPriority={submittingPriority}
        handlePrioritySubmit={handlePrioritySubmit}
        prioritySubmitError={prioritySubmitError}
        uncompletedPriorities={uncompletedPriorities}
      />

      {alreadyAnswered ? (
        <p className="text-slate-300 mb-4">
          You have already answered today's questions. Thank you!
        </p>
      ) : questions.length > 0 ? (
        <QuestionForm
          questions={questions}
          answers={answers}
          handleInputChange={handleInputChange}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          sliderConfirmed={sliderConfirmed}
          handleSliderConfirm={handleSliderConfirm}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      ) : (
        <p className="text-neutral-300 mt-5 p-2">
          Questions already answered. You're all good for the week!
        </p>
      )}

      {submitError && <p className="text-red-500 mt-2">{submitError}</p>}
    </div>
  );
};

export default CurrentQuestions;
