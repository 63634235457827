import React, { useState } from "react";
import { MessageCircle, Clock, Reply } from "lucide-react";

const CommentsSection = ({ comments, onReply }) => {
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyText, setReplyText] = useState("");

  if (!comments || !Array.isArray(comments) || comments.length === 0) {
    return null;
  }

  const hasValidUserId = (comment) => {
    return (
      typeof comment === "object" &&
      comment !== null &&
      comment.commenter_id &&
      comment.commenter_username
    );
  };

  const handleSubmitReply = (commentIndex) => {
    if (replyText.trim()) {
      onReply(replyText, comments[commentIndex].timestamp);
      setReplyText("");
      setReplyingTo(null);
    }
  };

  const renderComment = (textItem, isReply = false) => (
    <div
      className={`flex justify-between items-start ${isReply ? "ml-4 mt-2 pt-2 border-t border-neutral-500" : ""}`}
    >
      <div className="flex items-start gap-2">
        {textItem.commenter_id || hasValidUserId(textItem) ? (
          <span className="text-[#7d875a] font-medium text-sm">
            {textItem.commenter_username}:
          </span>
        ) : (
          <span className="text-neutral-300 font-medium text-sm">comment:</span>
        )}
        <span className="text-neutral-300 text-sm">
          {typeof textItem.text === "string" ? textItem.text : textItem}
        </span>
      </div>
      {!isReply && (
        <button
          onClick={() => setReplyingTo(comments.indexOf(textItem))}
          className="text-xs text-slate-400 hover:text-lime-600 flex items-center gap-1"
        >
          <Reply className="w-3 h-3" />
          Reply
        </button>
      )}
    </div>
  );

  return (
    <div className="mt-6 bg-neutral-900 rounded-md border border-neutral-800 p-4 shadow-2xl">
      <div className="flex items-center gap-2 mb-3">
        <MessageCircle className="w-4 h-4 text-neutral-300" />
        <h3 className="text-neutral-300 text-sm">Comments</h3>
      </div>
      <div className="space-y-3">
        {comments.map((comment, idx) => (
          <div
            key={idx}
            className="pb-3 border-b border-slate-800 last:border-0"
          >
            {/* Handle the main comment */}
            {Array.isArray(comment.text) ? (
              // For comments with nested replies
              <>
                {comment.text.map((textItem, textIdx) => (
                  <div key={textIdx}>
                    {renderComment(textItem, textIdx > 0)}
                    {textItem.timestamp && (
                      <div className="mt-1 flex items-center gap-1 text-gray-500">
                        <Clock className="w-3 h-3" />
                        <span className="text-xs">
                          {new Date(textItem.timestamp).toLocaleString()}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              // For direct comments
              <>
                {renderComment(comment)}
                {comment.timestamp && (
                  <div className="mt-1 flex items-center gap-1 text-gray-500">
                    <Clock className="w-3 h-3" />
                    <span className="text-xs">
                      {new Date(comment.timestamp).toLocaleString()}
                    </span>
                  </div>
                )}
              </>
            )}

            {/* Reply input */}
            {replyingTo === idx && (
              <div className="mt-3 pl-4 space-y-2">
                <textarea
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  className="w-full p-2 text-sm bg-slate-800 text-neutral-200 rounded-lg border border-slate-700 focus:border-lime-800/50 focus:ring-1 focus:ring-lime-800/20 transition-colors duration-200 resize-none"
                  placeholder="Write a reply..."
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => {
                      setReplyingTo(null);
                      setReplyText("");
                    }}
                    className="px-2 py-1 text-xs text-slate-400 hover:text-slate-300 transition-colors duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmitReply(idx)}
                    className="px-2 py-1 text-xs bg-slate-800 text-slate-300 rounded-lg border border-lime-800/20 hover:bg-lime-800/20 transition-colors duration-200"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentsSection;
