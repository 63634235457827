import React from "react";
import { MessageCircle, Clock } from "lucide-react";

const UserResponseCard = ({
  response,
  activeComment,
  setActiveComment,
  comments,
  setComments,
  handleCommentSubmit,
  handleResponseClick,
}) => {
  const isRead = response.read_responses;
  const cardId = `${response.user_id}-${response.date_answered}`;

  const hasValidUserId = (comment) => {
    return (
      typeof comment === "object" &&
      comment !== null &&
      comment.commenter_id &&
      comment.commenter_username
    );
  };

  // MODIFIED: renderComment function to handle both old and new formats
  const renderComment = (comment) => {
    // Handle legacy format (direct string)
    if (typeof comment === "string") {
      return <span className="text-neutral-300">{comment}</span>;
    }

    // Handle nested replies format
    if (Array.isArray(comment.text)) {
      return comment.text.map((reply, replyIdx) => (
        <div
          key={replyIdx}
          className={`text-xs ${replyIdx > 0 ? "ml-4 mt-2" : ""}`}
        >
          <div className="flex items-start gap-2">
            {/* Only show username if commenter_id exists */}
            {reply.commenter_id ? (
              <span className="text-neutral-800 font-semibold">
                {reply.commenter_username}:{" "}
              </span>
            ) : (
              <span className="text-neutral-300">comment: </span>
            )}
            <span className="text-neutral-300">{reply.text}</span>
          </div>
          {reply.timestamp && (
            <div className="mt-1 flex items-center gap-1 text-neutral-400">
              <Clock className="w-3 h-3" />
              <span className="text-xs">
                {new Date(reply.timestamp).toLocaleString()}
              </span>
            </div>
          )}
        </div>
      ));
    }
    // Handle single comment (both old and new format)
    return (
      <div className="text-xs text-neutral-300">
        {/* Only show username if commenter_id exists, otherwise show generic "comment:" */}
        {hasValidUserId(comment) ? (
          <>
            <span className="text-neutral-300">
              {comment.commenter_username}:{" "}
            </span>
            <span>
              {typeof comment.text === "string" ? comment.text : comment}
            </span>
          </>
        ) : (
          <>
            <span className="text-blue-300">comment: </span>
            <span>
              {typeof comment.text === "string" ? comment.text : comment}
            </span>
          </>
        )}
        {comment.timestamp && (
          <div className="mt-1 flex items-center gap-1 text-neutral-500">
            <Clock className="w-3 h-3" />
            <span className="text-xs">
              {new Date(comment.timestamp).toLocaleString()}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() =>
        !isRead && handleResponseClick(response.user_id, response.date_answered)
      }
      className={`p-4 rounded-lg transition-all duration-300 cursor-pointer
        ${
          isRead
            ? "bg-gradient-to-b from-slate-700 to bg-slate-900 border border-slate-800 shadow-2xl hover:bg-neutral-700"
            : "bg-slate-900 border border-neutral-800 hover:border-slate-500/40 shadow-2xl"
        }`}
    >
      <div className="space-y-4">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-neutral-200 text-sm font-semibold tracking-wide mb-1">
              {response.username}
            </h4>
            <p className="text-xs text-neutral-700">
              {new Date(response.date_answered).toLocaleDateString()}
            </p>
          </div>
          {!isRead && (
            <span className="px-2 py-1 text-xs bg-neutral-700 text-neutral-300 rounded-lg border border-neutral-300">
              New
            </span>
          )}
        </div>

        <div className="space-y-3">
          {[1, 2, 3, 4].map((num) => {
            const questionKey = `question_${num}`;
            const answerKey = `answer_${num}`;
            if (response[questionKey] && response[answerKey]) {
              return (
                <div key={num} className="space-y-4">
                  <p className="text-xs text-neutral-100 tracking-wide">
                    {response[questionKey]}
                  </p>
                  <p className="text-xs text-neutral-900 p-2 bg-neutral-300 rounded-lg border border-neutral-400">
                    {response[answerKey]}
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>

        <div className="pt-3 border-t border-slate-600">
          {response.comment && (
            <div className="mb-2 space-y-3">
              {(Array.isArray(response.comment)
                ? response.comment
                : [response.comment]
              ).map((comment, idx) => (
                <div
                  key={idx}
                  className="p-2 bg-neutral-500 rounded-lg border border-neutral-600"
                >
                  {renderComment(comment)}
                </div>
              ))}
            </div>
          )}

          {activeComment === cardId ? (
            <div className="space-y-3" onClick={(e) => e.stopPropagation()}>
              <textarea
                value={comments[cardId] || ""}
                onChange={(e) =>
                  setComments({ ...comments, [cardId]: e.target.value })
                }
                className="w-full p-3 text-sm bg-[#0A0A0B] text-[#9A9A9D] rounded-lg border border-sky-600/60 focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/20 transition-colors duration-200 resize-none"
                placeholder="Add a comment..."
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveComment(null);
                  }}
                  className="px-3 py-1.5 text-xs text-[#9A9A9D] bg-[#1F1F23] rounded-lg hover:bg-[#2F2F33] transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCommentSubmit(
                      response.user_id,
                      response.date_answered,
                    );
                  }}
                  className="px-3 py-1.5 text-xs bg-slate-800 text-slate-400 rounded-lg border border-cyan-500/20 hover:bg-cyan-500/20 transition-colors duration-200"
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveComment(cardId);
              }}
              className="flex items-center gap-2 text-sm text-[#E7EEE7] mt-6 mb-2 hover:text-neutral-950 transition-colors duration-200"
            >
              <MessageCircle
                className="w-4 h-4 text-[#E7EEE7]"
                strokeWidth={1.5}
              />
              Add Comment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserResponseCard;
