import React from "react";

const PriorityCheck = ({
  priority,
  priorityChecked,
  submittingPriority,
  handlePrioritySubmit,
  prioritySubmitError,
  uncompletedPriorities = [],
}) => {
  // Filter out the current priority from uncompleted priorities to avoid duplication
  const otherPriorities = uncompletedPriorities.filter(
    (p) => p.priority !== priority,
  );

  return (
    <>
      {/* Current user's priority */}
      <div className="mb-4 p-4 bg-gradient-to-b from-neutral-700 to bg-gray-950  rounded-lg mt-8">
        <h4 className="text-sm text-[#E7EEE7]">Priority for this week:</h4>
        <div className="flex mt-1 justify-between items-center">
          <p className="text-slate-200 text-sm">{priority}</p>
          <button
            onClick={() => handlePrioritySubmit()} // Call with no params for current user's priority
            disabled={submittingPriority || priorityChecked}
            className={`text-xs px-2 py-2 bg-neutral-600 text-neutral-100 rounded hover:bg-slate-900 focus:outline-none ml-auto ${
              submittingPriority || priorityChecked
                ? "opacity-60 cursor-not-allowed"
                : ""
            }`}
          >
            {priorityChecked ? "Priority Completed" : "Mark Completed"}
          </button>
        </div>

        {prioritySubmitError && (
          <p className="text-red-500 mt-1">{prioritySubmitError}</p>
        )}
      </div>

      {/* Other uncompleted priorities section */}
      {otherPriorities.length > 0 && (
        <div className="mb-6 p-4 bg-gradient-to-t from-slate-700 to-slate-800  rounded-lg">
          <h4 className="text-xs text-slate-300 mb-2">
            Previously Uncompleted:
          </h4>
          <div className="space-y-2">
            {otherPriorities.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center p-1 rounded"
              >
                <div className="space-y-1">
                  <p className="text-slate-200 text-sm">{item.priority}</p>
                  <p className="text-xs text-slate-400">
                    Set on: {new Date(item.date).toLocaleString("en-US")}
                  </p>
                </div>
                <button
                  onClick={() =>
                    handlePrioritySubmit({
                      userId: item.user_id,
                      date: item.date,
                    })
                  }
                  className="text-xs px-2 py-1 bg-slate-600/80 text-white rounded hover:bg-cyan-600 focus:outline-none transition-colors duration-200"
                >
                  Mark Complete
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PriorityCheck;
