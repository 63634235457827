// DashboardHeader.jsx
import React from "react";
import { LogOut, Menu, X } from "lucide-react";

const DashboardHeader = ({
  user,
  handleLogout,
  isSidebarOpen,
  toggleSidebar,
  getDisplayName,
}) => (
  <header className="bg-gradient-to-r from-neutral-300 to-neutral-400  px-4 lg:px-4">
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center space-x-2">
        <button
          onClick={toggleSidebar}
          className="md:hidden text-slate-200 p-2"
        >
          {isSidebarOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
        <img
          src="/matcha11.svg"
          alt="Matcha Logo"
          className="h-[35px] w-1/2 sm:h-[50px]"
        />
        {/*
<h1 className="text-3xl font-semibold text-[#4f5b32] font-serif">
          matcha
        </h1>

          */}
        <h1 className="text-lg lg:text-2xl text-neutral-800 font-serif">
          {getDisplayName(user.business_name)}
        </h1>
      </div>
      <button
        onClick={handleLogout}
        className="flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-md text-neutral-900 hover:text-neutral-100 focus:outline-none"
      >
        <LogOut className="mr-2 h-4 w-4" />
        Logout
      </button>
    </div>
  </header>
);

export default DashboardHeader;
