import React, { useState, useEffect } from "react";
import axios from "axios";
import { Share2 } from "lucide-react";

const SharedWithMe = ({ businessName, onReviewSelect }) => {
  const [sharedReviews, setSharedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSharedReviews();
  }, [businessName]);

  const fetchSharedReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/shared-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSharedReviews(response.data || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching shared reviews:", err);
      setError("Failed to fetch shared reviews");
      setLoading(false);
    }
  };

  const getReviewTitle = (reviewText) => {
    // If it's a team review, extract everything after the "Review for team member: username" part
    if (reviewText.includes("Review for team member:")) {
      const parts = reviewText.split("\n");
      if (parts.length > 1) {
        return parts[1].trim();
      }
    } else if (reviewText.includes("Review for")) {
      // For older format team reviews
      const parts = reviewText.split(":");
      if (parts.length > 1) {
        return parts.slice(1).join(":").trim();
      }
    }
    // If it's not a team review or doesn't match expected format, return as is
    return reviewText;
  };

  if (loading)
    return <div className="text-neutral-700">Loading shared reviews...</div>;
  if (error) return <div className="text-red-400">{error}</div>;

  return (
    <div className="space-y-4">
      {sharedReviews.length === 0 ? (
        <p className="text-gray-500 text-sm">
          No reviews have been shared with you.
        </p>
      ) : (
        sharedReviews.map((review) => (
          <div
            key={review.id}
            className="p-2 bg-gradient-to-b from-slate-800 to-neutral-950 rounded-lg border border-slate-700"
          >
            <h3 className="ml-2 text-sm text-neutral-200">
              {getReviewTitle(review.review_text)}
            </h3>
            <div className="mt-2 ml-2 flex items-center justify-between">
              <p className="text-sm text-neutral-400">
                Shared by: {review.shared_by_username} ·{" "}
                {new Date(review.shared_at).toLocaleDateString()}
              </p>
              <button
                onClick={() => onReviewSelect(review)}
                className="px-3 py-1.5 bg-neutral-300 text-xs font-serif text-neutral-800 rounded-md border border-neutral-500 hover:bg-slate-700 transition-colors duration-200 whitespace-nowrap flex-shrink-0"
              >
                {" "}
                View Answers
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SharedWithMe;
