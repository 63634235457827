import React, { useState, useEffect, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import { MoreVertical, Trash2 } from "lucide-react";

const QuestionItem = ({ id, text, onDeleteQuestion }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "question",
    item: { id, text },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`bg-neutral-500 p-3 rounded-lg shadow-md transition-colors duration-200 flex justify-between items-center mb-2 ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <span className="text-slate-200">{text}</span>
      <div className="flex items-center">
        <div className="cursor-move mr-2 text-slate-400">
          <MoreVertical size={20} />
        </div>
        <button
          onClick={() => onDeleteQuestion(id)}
          className="text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <Trash2 size={20} />
        </button>
      </div>
    </div>
  );
};

const QuestionSection = ({ title, questionId, questions, onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "question",
    drop: (item) => onDrop(item.id, title),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const question = questions.find((q) => q.id === questionId);

  return (
    <div
      ref={drop}
      className={`bg-slate-800 p-4 rounded-lg mb-4 ${
        isOver ? "border-2 border-neutral-500" : ""
      }`}
    >
      <h5 className="text-lg font-semibold mb-2 text-indigo-300">{title}</h5>
      {question && (
        <QuestionItem
          id={question.id}
          text={question.question_text}
          onDeleteQuestion={() => {}}
        />
      )}
    </div>
  );
};

const QuestionOrder = ({ businessName, orderId, onDeleteQuestion }) => {
  const [questions, setQuestions] = useState([]);
  const [questionOrder, setQuestionOrder] = useState({
    static_q_1: null,
    static_q_2: null,
    q_3: null,
    q_4: null,
    remaining_questions: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      const [questionsResponse, orderResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/questions`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(
          `/api/v1/businesses/${businessName}/question-order/${orderId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
      ]);
      setQuestions(questionsResponse.data || []);
      setQuestionOrder(
        orderResponse.data || {
          static_q_1: null,
          static_q_2: null,
          q_3: null,
          q_4: null,
          remaining_questions: [],
        },
      );
      setError(null);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Failed to fetch questions. Please try again.");
      setQuestions([]);
    } finally {
      setLoading(false);
    }
  }, [businessName, orderId]);

  useEffect(() => {
    fetchQuestions();

    const handleQuestionsUpdated = () => {
      fetchQuestions();
    };

    window.addEventListener("questionsUpdated", handleQuestionsUpdated);
    return () => {
      window.removeEventListener("questionsUpdated", handleQuestionsUpdated);
    };
  }, [fetchQuestions]);

  const handleDrop = useCallback((questionId, sectionTitle) => {
    setQuestionOrder((prev) => {
      const newOrder = { ...prev };
      Object.keys(newOrder).forEach((key) => {
        if (newOrder[key] === questionId) {
          newOrder[key] = null;
        }
      });
      newOrder[sectionTitle] = questionId;
      return newOrder;
    });
  }, []);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.post(
        `/api/v1/businesses/${businessName}/question-order/${orderId}`,
        questionOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      alert(`Question order saved successfully!`);
    } catch (error) {
      console.error("Error saving question order:", error);
      setError("Failed to save question order. Please try again.");
    }
  };

  if (loading) return <div className="text-white">Loading questions...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  const assignedQuestionIds = Object.keys(questionOrder)
    .filter((key) => key !== "remaining_questions")
    .map((key) => questionOrder[key])
    .filter((id) => id !== null && id !== undefined);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mt-1 bg-neutral-800 p-4">
        <h4 className="text-2xl mb-5 text-slate-200">
          {orderId === 1 ? "First Question Set" : "Second Question Set"}
        </h4>

        <div className="grid grid-cols-2 gap-4">
          <QuestionSection
            title="static_q_1"
            questionId={questionOrder.static_q_1}
            questions={questions}
            onDrop={handleDrop}
          />
          <QuestionSection
            title="static_q_2"
            questionId={questionOrder.static_q_2}
            questions={questions}
            onDrop={handleDrop}
          />
          <QuestionSection
            title="q_3"
            questionId={questionOrder.q_3}
            questions={questions}
            onDrop={handleDrop}
          />
          <QuestionSection
            title="q_4"
            questionId={questionOrder.q_4}
            questions={questions}
            onDrop={handleDrop}
          />
        </div>

        <div className="mt-4">
          <h5 className="text-lg mb-4 text-slate-200">remaining questions</h5>
          {questions
            .filter((question) => !assignedQuestionIds.includes(question.id))
            .map((question) => (
              <QuestionItem
                key={question.id}
                id={question.id}
                text={question.question_text}
                onDeleteQuestion={onDeleteQuestion}
              />
            ))}
        </div>

        <button
          onClick={handleSubmit}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors duration-200"
        >
          Save Question Order
        </button>
      </div>
    </DndProvider>
  );
};

export default QuestionOrder;
