import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const CreateReview = () => {
  const { businessName } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const [questions, setQuestions] = useState(Array(8).fill(""));
  const [error, setError] = useState(null);
  const [isCustom, setIsCustom] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/template-reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTemplates(response.data);
    } catch (err) {
      console.error("Error fetching templates:", err);
      setError("Failed to fetch templates. Please try again.");
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setReviewText(template.template_name);
    setQuestions([
      template.q_id_1,
      template.q_id_2,
      template.q_id_3,
      template.q_id_4,
      template.q_id_5,
      template.q_id_6,
      template.q_id_7,
      template.q_id_8,
    ]);
    setIsCustom(false);
  };

  const handleCustomSelect = () => {
    setSelectedTemplate(null);
    setReviewText("");
    setQuestions(Array(8).fill(""));
    setIsCustom(true);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("access_token");
      let endpoint, payload;

      if (isCustom) {
        endpoint = `/api/v1/businesses/${businessName}/reviews`;
        payload = {
          reviews_text: reviewText,
          question_1: questions[0],
          question_2: questions[1],
          question_3: questions[2],
          question_4: questions[3],
          question_5: questions[4],
          question_6: questions[5],
          question_7: questions[6],
          question_8: questions[7],
        };
      } else {
        endpoint = `/api/v1/businesses/${businessName}/fork-template`;
        payload = {
          template_id: selectedTemplate.id,
          reviews_text: reviewText,
          questions: questions.filter((q) => q !== ""),
        };
      }

      const response = await axios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Review created:", response.data);
      navigate(`/businesses/${businessName}/dashboard`, {
        state: { activeSection: "Create Reviews" },
      });
    } catch (err) {
      console.error("Error creating review:", err);
      setError("Failed to create review. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-indigo-900 px-4 py-8">
      <div className="w-full max-w-4xl bg-slate-800 rounded-lg shadow-2xl">
        <div className="p-8">
          <h3 className="text-2xl mb-6 text-teal-300 font-serif">
            create new review
          </h3>
          <div className="mb-6">
            <h4 className="text-xl font-semibold mb-4 text-white font-serif">
              select a template or create custom
            </h4>
            <div className="grid grid-cols-2 gap-4">
              {templates.map((template) => (
                <button
                  key={template.id}
                  onClick={() => handleTemplateSelect(template)}
                  className={`p-4 rounded-lg ${
                    selectedTemplate?.id === template.id
                      ? "bg-teal-500"
                      : "bg-gray-400"
                  } hover:bg-teal-500 transition-colors`}
                >
                  {template.template_name}
                </button>
              ))}
              <button
                onClick={handleCustomSelect}
                className={`p-4 rounded-lg ${
                  isCustom ? "bg-blue-600" : "bg-gray-400"
                } hover:bg-teal-500 transition-colors`}
              >
                Custom
              </button>
            </div>
          </div>
          {(selectedTemplate || isCustom) && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                className="w-full p-3 bg-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                placeholder="Review title"
                required
              />
              {questions.map((question, index) => (
                <div key={index}>
                  <input
                    type="text"
                    value={question}
                    onChange={(e) =>
                      handleQuestionChange(index, e.target.value)
                    }
                    className="w-full p-3 bg-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    placeholder={`Question ${index + 1}`}
                  />
                </div>
              ))}
              <button
                type="submit"
                className="w-full px-4 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              >
                Submit Review
              </button>
            </form>
          )}
          {error && <div className="mt-4 text-red-500">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default CreateReview;
