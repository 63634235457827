import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../utils/apiConfig";
import ENPSStats from "./ENPS/ENPSStats";

const ENPS = ({ businessName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendToAll, setSendToAll] = useState(false);
  const [verifyEmails, setVerifyEmails] = useState(false); // New state for email verification
  const [questions, setQuestions] = useState([
    { id: 1, text: "", required: true },
    { id: 2, text: "", required: true },
    { id: 3, text: "", required: true },
    { id: 4, text: "", required: true },
    { id: 5, text: "", required: false },
  ]);

  const handleQuestionChange = (id, text) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, text } : q)));
  };

  const handleEmailChange = (e) => {
    setEmails(e.target.value);
  };

  const handleSendToAllChange = (e) => {
    setSendToAll(e.target.checked);
    if (e.target.checked) {
      setVerifyEmails(false); // Reset verify emails when sending to all
    }
  };

  const handleVerifyEmailsChange = (e) => {
    setVerifyEmails(e.target.checked);
    setError(null); // Clear any existing errors when toggling
  };

  const handleSendENPS = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const emailList = sendToAll
      ? []
      : emails
          .split(/[,\s]+/)
          .map((email) => email.trim())
          .filter((email) => email !== "");

    if (
      questions.some((q) => q.required && q.text.trim() === "") ||
      (!sendToAll && emailList.length === 0)
    ) {
      setError(
        'Please fill in all required questions and provide at least one email address (or select "Send to All Users") before sending the survey.',
      );
      setIsLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("access_token");

      // If verifyEmails is true, first validate the emails
      if (verifyEmails && !sendToAll) {
        const validateResponse = await axios.post(
          `${API_URL}/api/v1/businesses/${businessName}/validate-emails`,
          { emails: emailList },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        if (validateResponse.data.invalidEmails?.length > 0) {
          setError(
            `The following emails are not registered users: ${validateResponse.data.invalidEmails.join(
              ", ",
            )}`,
          );
          setIsLoading(false);
          return;
        }
      }

      // Send the survey
      const response = await axios.post(
        `${API_URL}/api/v1/businesses/${businessName}/create-enps-survey`,
        {
          questions: questions.map((q) => q.text),
          emails: emailList,
          send_to_all: sendToAll,
          verify_emails: verifyEmails,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setSuccess(
        `Survey created and sent to ${
          sendToAll ? "all users" : emailList.length
        } recipient(s)`,
      );
    } catch (err) {
      console.error("Error sending eNPS:", err.response?.data || err.message);
      setError(
        `Failed to send eNPS: ${err.response?.data?.detail || err.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-t from-neutral-300 to-neutral-200 shadow-2xl p-6 mt-6">
      <div>
        <ENPSStats businessName={businessName} />
      </div>
      <h3 className="bg-gradient-to-b from-slate-700 to-neutral-900 text-neutral-200 p-2 mt-1 mb-4 text-center font-serif">
        employee net promoter score
      </h3>
      <form onSubmit={handleSendENPS}>
        <div className="space-y-4 mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={sendToAll}
              onChange={handleSendToAllChange}
              className="mr-2"
            />
            <span className="text-slate-950 text-sm tracking-wide">
              send to all users
            </span>
          </label>

          {!sendToAll && (
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={verifyEmails}
                onChange={handleVerifyEmailsChange}
                className="mr-2"
              />
              <span className="text-slate-950 text-sm tracking-wide">
                verify emails against registered users
              </span>
            </label>
          )}
        </div>

        {!sendToAll && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-neutral-800 mb-2">
              email addresses (separated by commas or spaces)
              {verifyEmails && (
                <span className="text-gray-500 text-xs ml-2">
                  (must be registered users)
                </span>
              )}
            </label>
            <input
              type="text"
              value={emails}
              onChange={handleEmailChange}
              className="w-full p-2 bg-slate-700 text-white rounded"
              placeholder="Enter email addresses"
            />
          </div>
        )}

        <div className="grid grid-cols-2 gap-4">
          {questions.map((question, index) => (
            <div key={question.id} className="mb-4">
              <label className="block text-sm font-medium text-slate-800 mb-2">
                Question {index + 1}
                {!question.required && (
                  <span className="text-gray-500 text-xs ml-2">(Optional)</span>
                )}
              </label>
              <input
                type="text"
                value={question.text}
                onChange={(e) =>
                  handleQuestionChange(question.id, e.target.value)
                }
                className="w-full p-2 bg-slate-700 text-white rounded"
                placeholder={`Enter question ${index + 1}`}
                required={question.required}
              />
            </div>
          ))}
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full py-2 px-4 bg-slate-900 mt-6 text-slate-200 font-serif tracking-wider hover:bg-teal-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          {isLoading ? "sending..." : "send eNPS survey"}
        </button>
      </form>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {success && (
        <p className="text-slate-200 mt-6 font-semibold">{success}</p>
      )}
    </div>
  );
};

export default ENPS;
