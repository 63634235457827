// DashboardContent.jsx
import React from "react";
import { X } from "lucide-react";
import CurrentQuestions from "../VibeCheck/CurrentQuestions";

// DashboardContent.jsx
const DashboardContent = ({
  user,
  latestSurveyResponse,
  averageScore,
  lastCalculated,
  averageScoreError,
  getDisplayName,
}) => {
  return (
    <div className="mt-4 bg-gradient-to-b from-slate-700 to-slate-900 ml-2 rounded-lg shadow-2xl font-serif border border-neutral-800">
      <h2 className="flex justify-end text-slate-300 text-lg font-serif mr-3 mt-2 p-2">
        welcome,{" "}
        <p className="text-neutral-200 ml-2 font-semibold">{user.username}</p>
      </h2>
      <div className="grid grid-cols-2 gap-3 mb-2 text-sm">
        {latestSurveyResponse !== null && (
          <p>
            <span className="text-gray-400">Your Latest Rating:</span>{" "}
            {latestSurveyResponse}/5
          </p>
        )}
        {user.is_admin && (
          <div className="col-span-2 bg-gradient-to-t from-neutral-600 to-neutral-800  p-4 shadow-2xl rounded-md text-right mr-5">
            {averageScore !== null ? (
              <>
                <p className="text-lg text-neutral-100">
                  {getDisplayName(user.business_name)} vibe-check avg:{" "}
                  {averageScore.toFixed(2)}
                </p>
                {lastCalculated && (
                  <p className="text-xs mt-3 text-slate-400">
                    calculated: {new Date(lastCalculated).toLocaleString()}
                  </p>
                )}
              </>
            ) : (
              <p className="text-red-500">{averageScoreError}</p>
            )}
          </div>
        )}
      </div>
      <CurrentQuestions businessName={user.business_name} />
    </div>
  );
};

export default DashboardContent;
