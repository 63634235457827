import React from "react";
import { MessageCircle, Clock } from "lucide-react";
import MeetingCard from "./MeetingCard";

const MeetingsListOneonOne = ({
  selectedUserMeetings,
  notes,
  editingNotes,
  onNoteChange,
  onUpdateNotes,
  onEditingNotesChange,
}) => {
  return (
    <div className="bg-gradient-to-b from-neutral-300 to-neutral-400 divide-y divide-slate-600 rounded-lg">
      <div className="p-4 flex items-center justify-between bg-gradient-to-b from-slate-600 to-slate-950 rounded ">
        <div className="flex items-center space-x-3 ">
          <div className="h-[2px] w-8 bg-gradient-to-r from-neutral-400 to-transparent" />
          <span className="text-neutral-100 text-sm tracking-wider">
            Scheduled Meetings
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="w-4 h-4 text-neutral-300" strokeWidth={1.5} />
          <span className="text-neutral-300 text-xs">
            {selectedUserMeetings.length} Total
          </span>
        </div>
      </div>

      <div className="p-4">
        {selectedUserMeetings.length === 0 ? (
          <div className="py-12 flex flex-col items-center justify-center">
            <MessageCircle
              className="w-8 h-8 text-[#1F1F23] mb-3"
              strokeWidth={1.5}
            />
            <p className="text-neutral-800 text-sm">No meetings scheduled</p>
          </div>
        ) : (
          <div className="space-y-3">
            {selectedUserMeetings.map((meeting) => (
              <MeetingCard
                key={meeting.id}
                meeting={meeting}
                isAttendant={meeting.is_attendant}
                notes={notes}
                editingNotes={editingNotes}
                onNoteChange={onNoteChange}
                onUpdateNotes={onUpdateNotes}
                onEditingNotesChange={(meetingId, value) =>
                  onEditingNotesChange(meetingId, value)
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingsListOneonOne;
