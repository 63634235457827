import axios from "axios";
import { useState, useEffect } from "react";

export const useReviews = (businessName) => {
  const [answeredReviews, setAnsweredReviews] = useState([]);
  const [unansweredReviews, setUnansweredReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    //clare the existing state first
    setAnsweredReviews([]);
    setUnansweredReviews([]);

    try {
      const [answeredResponse, reviewsResponse] = await Promise.all([
        axios.get(`/api/v1/businesses/${businessName}/my-answered-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
        axios.get(`/api/v1/businesses/${businessName}/pushed-reviews`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }),
      ]);

      const answered = answeredResponse.data.answered_reviews || [];
      const pushedReviews = reviewsResponse.data.active_reviews || [];

      setAnsweredReviews(answered);

      if (pushedReviews.length > 0) {
        const answeredReviewIds = new Set(answered.map((review) => review.id));
        const filteredReviews = pushedReviews.filter(
          (review) => !answeredReviewIds.has(review.id),
        );
        setUnansweredReviews(filteredReviews);
      } else {
        setUnansweredReviews([]);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/all-users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setUsers(response.data || []);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  const handleRecordClick = async (reviewId, selectedUser) => {
    if (!selectedUser) {
      alert("Please select a user to share with.");
      return;
    }
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/record-review-click/${reviewId}`,
        { shared_with: parseInt(selectedUser) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Click recorded successfully");
      return true;
    } catch (err) {
      console.error("Error recording review click:", err);
      return false;
    }
  };

  const handleSubmitAnswers = async (reviewId, answers) => {
    try {
      await axios.post(
        `/api/v1/businesses/${businessName}/submit-review-answer`,
        {
          review_id: reviewId,
          answers: answers,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      alert("Answers submitted successfully!");
      setLoading(true);
      fetchData();
      return true;
    } catch (err) {
      console.error("Error submitting answers:", err);
      alert("Failed to submit answers. Please try again.");
      return false;
    }
  };

  useEffect(() => {
    fetchData();
    fetchUsers();
  }, [businessName]);

  return {
    answeredReviews,
    unansweredReviews,
    loading,
    error,
    users,
    handleRecordClick,
    handleSubmitAnswers,
    refreshData: fetchData,
  };
};
