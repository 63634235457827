import React, { useState } from "react";
import TeamReviewPage from "../Teams/TeamReviewPage";
import SharedWithMe from "../Shared/SharedWithMe";
import ReviewModal from "./ReviewModal";
import AnsweredReviewsSection from "./AnsweredReviewsSection";
import ReviewsToAnswerSection from "./ReviewsToAnswerSection";
import { MessageSquare, Share2, Users } from "lucide-react";
import { useReviews } from "./hooks/useReviews";

const MyReviews = ({ businessName }) => {
  const {
    answeredReviews,
    unansweredReviews,
    loading,
    error,
    users,
    handleRecordClick,
    handleSubmitAnswers,
  } = useReviews(businessName);

  const [selectedReview, setSelectedReview] = useState(null);
  const [answers, setAnswers] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalType, setActiveModalType] = useState("");
  const [showUserDropdown, setShowUserDropdown] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
  };

  const handleReviewSelect = (review, modalType) => {
    setSelectedReview(review);
    setAnswers({});
    if (modalType === "sharedWithMe") {
      const prefilledAnswers = {};
      review.questions.forEach((question, index) => {
        prefilledAnswers[`question_${index + 1}`] = question;
        prefilledAnswers[`answer_${index + 1}`] = review.answers[index] || "";
      });
      setAnswers(prefilledAnswers);
    }
    setModalOpen(true);
    setActiveModalType(modalType);
  };

  const handleAnswerChange = (questionKey, value) => {
    setAnswers({ ...answers, [questionKey]: value });
  };

  const handleSubmitAnswersWrapper = async () => {
    const success = await handleSubmitAnswers(selectedReview.id, answers);
    if (success) {
      setSelectedReview(null);
      setAnswers({});
      setModalOpen(false);
    }
  };

  const handleRecordClickWrapper = async (reviewId) => {
    const success = await handleRecordClick(reviewId, selectedUser);
    if (success) {
      setShowUserDropdown(null);
      setSelectedUser("");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedReview(null);
    setActiveModalType("");
  };

  if (loading) return <div className="font-serif">Loading reviews...</div>;
  if (error) return <div className="text-red-500 font-serif">{error}</div>;

  return (
    <div className="bg-gradient-to-b from-neutral-300 to-neutral-400 relative rounded-md shadow-2xl mt-6">
      <div className="max-w-8xl mx-auto px-4 sm:px-6 py-6">
        {/* Header */}
        <div className="flex justify-center rounded items-center bg-gradient-to-b from-slate-800 to-neutral-950 border border-slate-300 mb-6">
          <h1 className="text-md sm:text-md tracking-widest p-2">Reviews</h1>
          <img
            src="/assets/SVG/Success.svg"
            alt=""
            className="w-[33px] h-1/2 p-2 mr-2"
          />
        </div>

        <div className="space-y-4">
          {/* My Answered Reviews Section */}
          <AnsweredReviewsSection
            answeredReviews={answeredReviews}
            handleReviewSelect={handleReviewSelect}
            businessName={businessName}
            showUserDropdown={showUserDropdown}
            setShowUserDropdown={setShowUserDropdown}
            selectedUser={selectedUser}
            handleUserSelect={handleUserSelect}
            handleRecordClick={handleRecordClickWrapper}
            users={users}
          />

          {/* Reviews to Answer Section */}
          <ReviewsToAnswerSection
            unansweredReviews={unansweredReviews}
            selectedReview={selectedReview}
            handleReviewSelect={handleReviewSelect}
          />

          {/* Shared With Me Section */}
          <div className="bg-gradient-to-b from-neutral-100 to-neutral-300 rounded-lg p-3 border border-neutral-300 backdrop-blur-sm">
            <div className="bg-gradient-to-b from-[#4f5b32] to-neutral-950 rounded-md mb-3 mt-1 p-0.5">
              <h2 className="justify-center text-sm sm:text-md mb-3 mt-1 text-stone-300 flex items-center gap-2">
                <Share2 className="w-4 h-4 text-stone-300 ml-2" />
                Shared With Me
              </h2>
            </div>
            <SharedWithMe
              businessName={businessName}
              onReviewSelect={(review) =>
                handleReviewSelect(review, "sharedWithMe")
              }
            />
          </div>

          {/* Team Reviews Section */}
          <div className="bg-gradient-to-b from-neutral-300 to-neutral-400 rounded-lg p-4 border border-neutral-300 backdrop-blur-sm">
            <h2 className="justify-center bg-gradient-to-b from-[#4f5b32] to-neutral-950 p-2 rounded sm:text-md text-neutral-200 flex items-center gap-2">
              <Users className="w-4 h-4 text-neutral-200" />
              Team Reviews
            </h2>
            <TeamReviewPage
              businessName={businessName}
              onReviewSelect={(review) =>
                handleReviewSelect(review, "teamReviews")
              }
            />
          </div>
        </div>

        {/* Modal Component */}
        <ReviewModal
          isOpen={modalOpen}
          onClose={closeModal}
          selectedReview={selectedReview}
          activeModalType={activeModalType}
          answers={answers}
          handleAnswerChange={handleAnswerChange}
          handleSubmitAnswers={handleSubmitAnswersWrapper}
        />
      </div>
    </div>
  );
};

export default MyReviews;
