import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../utils/apiConfig";
import { ChartBar, Users, Download } from "lucide-react";

const ENPSStats = ({ businessName }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${API_URL}/api/v1/businesses/${businessName}/enps-stats`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        setStats(response.data);
      } catch (err) {
        setError("Failed to load ENPS statistics");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [businessName]);

  const handleExport = async () => {
    try {
      setExporting(true);
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `${API_URL}/api/v1/businesses/${businessName}/enps-export`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        },
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `enps_responses_${businessName}_${new Date().toISOString().split("T")[0]}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error("Export failed:", err);
      alert("Failed to export data. Please try again.");
    } finally {
      setExporting(false);
    }
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-40">
        <div className="text-slate-500">Loading statistics...</div>
      </div>
    );

  if (error) return <div className="text-red-500 p-4 text-center">{error}</div>;

  return (
    <div className="bg-gradient-to-t from-neutral-300 to-neutral-200 shadow-2xl p-6 mb-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="bg-gradient-to-b from-slate-700 to-neutral-900 text-neutral-200 p-2 text-center font-serif flex-grow">
          ENPS Review Statistics
        </h3>
        <button
          onClick={handleExport}
          disabled={exporting || stats.answered_reviews === 0}
          className="ml-4 flex items-center gap-2 px-4 py-2 bg-slate-700 text-white rounded hover:bg-slate-600 disabled:bg-slate-400 disabled:cursor-not-allowed"
        >
          <Download className="h-4 w-4" />
          {exporting ? "Exporting..." : "Export CSV"}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex items-center gap-2 mb-4">
            <ChartBar className="h-5 w-5 text-slate-700" />
            <h3 className="font-medium text-slate-900">
              ENPS Score: {stats.enps_score}
            </h3>
          </div>
          <div className="space-y-3">
            <div className="flex justify-between text-sm text-slate-800">
              <span>Promoters (9-10):</span>
              <span className="font-medium">{stats.promoters_percentage}%</span>
            </div>
            <div className="w-full bg-slate-200 rounded-full h-2">
              <div
                className="bg-green-600 h-2 rounded-full transition-all duration-500"
                style={{ width: `${stats.promoters_percentage}%` }}
              />
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Passives (7-8):</span>
              <span className="font-medium">{stats.passives_percentage}%</span>
            </div>
            <div className="w-full bg-slate-200 rounded-full h-2">
              <div
                className="bg-yellow-600 h-2 rounded-full transition-all duration-500"
                style={{ width: `${stats.passives_percentage}%` }}
              />
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Detractors (0-6):</span>
              <span className="font-medium">
                {stats.detractors_percentage}%
              </span>
            </div>
            <div className="w-full bg-slate-200 rounded-full h-2">
              <div
                className="bg-red-600 h-2 rounded-full transition-all duration-500"
                style={{ width: `${stats.detractors_percentage}%` }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex items-center gap-2 mb-4">
            <Users className="h-5 w-5 text-slate-700" />
            <h3 className="font-medium text-slate-900">Response Overview</h3>
          </div>
          <div className="space-y-3">
            <div className="flex justify-between text-sm text-slate-800">
              <span>Total Responses:</span>
              <span className="font-medium">{stats.answered_reviews}</span>
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Promoters:</span>
              <span className="font-medium">{stats.promoters}</span>
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Passives:</span>
              <span className="font-medium">{stats.passives}</span>
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Detractors:</span>
              <span className="font-medium">{stats.detractors}</span>
            </div>
            <div className="flex justify-between text-sm text-slate-800 mt-4">
              <span>Recent Reviews (30 days):</span>
              <span className="font-medium">{stats.recent_reviews}</span>
            </div>
            <div className="flex justify-between text-sm text-slate-800">
              <span>Recent Responses:</span>
              <span className="font-medium">{stats.recent_answered}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ENPSStats;
