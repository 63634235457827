import React from "react";
import { MessageSquare, Clock } from "lucide-react";

const ReviewsToAnswerSection = ({
  unansweredReviews,
  selectedReview,
  handleReviewSelect,
}) => {
  return (
    <div className="bg-gradient-to-b from-neutral-200 to-neutral-400 rounded-lg p-4 border border-neutral-300 shadow-lg backdrop-blur-sm">
      <div className="bg-gradient-to-b from-[#4f5b32] to-neutral-950">
        <h2 className="justify-center sm:text-md mb-3 text-neutral-300 flex items-center gap-1 p-1">
          <MessageSquare className="w-4 h-4 text-stone-200" />
          Reviews to Answer
        </h2>
      </div>
      {unansweredReviews.length === 0 ? (
        <p className="text-neutral-800 text-center py-3 font-serif">
          No reviews to answer at the moment.
        </p>
      ) : (
        <div className="space-y-2 max-h-[30vh] overflow-y-auto custom-scrollbar pr-2">
          {unansweredReviews.map((review) => (
            <div
              key={review.id}
              className={`bg-neutral-700 p-3 rounded-md border transition-all duration-200 cursor-pointer hover:shadow-lg
                ${
                  selectedReview?.id === review.id
                    ? "border-neutral-400"
                    : "border-slate-700/50 hover:border-slate-200"
                }`}
              onClick={() => handleReviewSelect(review, "reviewsToAnswer")}
            >
              <div className="space-y-1.5">
                <h3 className="text-sm sm:text-base font-serif text-slate-200">
                  {review.reviews_text}
                </h3>
                <p className="text-xs text-neutral-300 flex items-center gap-1.5">
                  <Clock className="w-3 h-3" />
                  Expires on:{" "}
                  {new Date(review.expiration_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewsToAnswerSection;
