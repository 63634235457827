import React, { useState, useEffect } from "react";
import axios from "axios";

const SentimentAnalysis = ({ businessName }) => {
  const [sentimentAnalysis, setSentimentAnalysis] = useState(null);
  const [sentimentLoading, setSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);
  const [emails, setEmails] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState("");

  useEffect(() => {
    fetchSentimentAnalysis();
  }, [businessName]);

  const fetchSentimentAnalysis = async () => {
    setSentimentLoading(true);
    try {
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/sentiment-analysis`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setSentimentAnalysis(response.data);
      setSentimentLoading(false);
    } catch (err) {
      console.error("Error fetching sentiment analysis:", err);
      setSentimentError("Failed to fetch sentiment analysis.");
      setSentimentLoading(false);
    }
  };

  const sendAnalysisEmail = async () => {
    if (!emails.trim()) {
      setEmailSentMessage("Please enter at least one email address.");
      return;
    }
    setSendingEmail(true);
    setEmailSentMessage("");
    try {
      const emailList = emails
        .split(/[ ,]+/)
        .filter((email) => email.trim() !== "");
      await axios.post(
        `/api/v1/businesses/${businessName}/send-sentiment-analysis`,
        { emails: emailList },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        },
      );
      setEmailSentMessage("Sentiment analysis sent successfully!");
      setEmails("");
    } catch (err) {
      console.error("Error sending sentiment analysis email:", err);
      setEmailSentMessage(
        "Failed to send sentiment analysis. Please try again.",
      );
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <div className="mt-6 bg-gradient-to-b from-slate-800 to-[#0a0a0a] border border-slate-800  p-4 rounded-lg">
      <h4 className="text-md mb-2 text-slate-200">Sentiment Analysis</h4>
      {sentimentLoading ? (
        <p>Loading sentiment analysis...</p>
      ) : sentimentError ? (
        <p className="text-red-300">{sentimentError}</p>
      ) : (
        <>
          {sentimentAnalysis && (
            <div className="mb-2 text-sm text-gray-400">
              <p>Index: {sentimentAnalysis.id}</p>
              <p>
                Generated at:{" "}
                {new Date(sentimentAnalysis.created_at).toLocaleString()}
              </p>
            </div>
          )}
          <p className="text-gray-300 whitespace-pre-line">
            {sentimentAnalysis.sentiment_analysis}
          </p>
        </>
      )}
      <div className="mt-4">
        <input
          type="text"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Enter emails (comma or space separated)"
          className="w-full p-2 bg-gray-700 text-white rounded"
        />
        <button
          onClick={sendAnalysisEmail}
          className="mt-4 px-4 text-sm py-2 bg-gradient-to-b from-neutral-300 to-neutral-700 border border-neutral-600 text-white rounded hover:bg-yellow-600"
          disabled={sendingEmail}
        >
          {sendingEmail ? "Sending..." : "Send Analysis"}
        </button>
        {emailSentMessage && (
          <p
            className={`mt-2 ${emailSentMessage.includes("success") ? "text-green-500" : "text-red-500"}`}
          >
            {emailSentMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default SentimentAnalysis;
