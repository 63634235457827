import React from "react";
import { X, Send } from "lucide-react";

const ReviewModal = ({
  isOpen,
  onClose,
  selectedReview,
  activeModalType,
  answers,
  handleAnswerChange,
  handleSubmitAnswers,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-800/70 backdrop-blur-sm p-4">
      <div className="bg-gradient-to-b from-neutral-300 to-neutral-500 rounded-xl border border-neutral-600 shadow-2xl w-full max-w-2xl my-6">
        {/* Modal Header */}
        <div className="border-b border-neutral-400 p-4 sm:p-6 top-0">
          <div className="flex justify-between items-center">
            <h2 className="text-lg text-neutral-900 font-semibold">
              {activeModalType === "reviewsToAnswer"
                ? "Answer Review"
                : activeModalType === "teamReviews"
                  ? "Team Review"
                  : activeModalType === "sharedWithMe"
                    ? "Shared Review"
                    : "Answered Review"}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-red-400 transition-colors duration-200"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          <h3 className="mt-2 text-md text-neutral-700">
            {selectedReview.reviews_text || selectedReview.review_text}
          </h3>
        </div>

        {/* Modal Content */}
        <div className="p-4 sm:p-6 space-y-4 overflow-y-auto max-h-[70vh] font-serif">
          {activeModalType === "sharedWithMe"
            ? selectedReview.questions.map((question, index) => (
                <div key={index} className="space-y-2">
                  <label className="text-sm font-medium text-neutral-800">
                    {question}
                  </label>
                  <textarea
                    className="w-full px-3 py-2 bg-neutral-800 rounded-lg border border-slate-700 text-neutral-300 text-sm whitespace-pre-wrap break-words max-w-full"
                    rows="4"
                    value={answers[`answer_${index + 1}`] || ""}
                    readOnly
                  />
                </div>
              ))
            : activeModalType === "answeredReviews"
              ? selectedReview.questions.map((question, index) => (
                  <div key={index} className="space-y-2">
                    <label className="text-sm font-medium text-neutral-900">
                      {question}
                    </label>
                    <p className="p-3 bg-neutral-700 rounded-lg border border-slate-700 text-slate-200 text-sm whitespace-pre-wrap break-words">
                      {selectedReview.answers[index] || "Not answered"}
                    </p>
                  </div>
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                  <div key={num} className="space-y-2">
                    <label className="text-sm font-medium text-slate-100">
                      {selectedReview[`question_${num}`]}
                    </label>
                    <textarea
                      className="w-full px-3 py-2 bg-slate-900 rounded-lg border border-slate-700 text-slate-100 text-sm focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 transition-colors duration-200 whitespace-pre-wrap break-words max-w-full"
                      rows="3"
                      value={answers[`answer_${num}`] || ""}
                      onChange={(e) =>
                        handleAnswerChange(`answer_${num}`, e.target.value)
                      }
                      readOnly={activeModalType === "answeredReviews"}
                    />
                  </div>
                ))}

          {activeModalType !== "sharedWithMe" &&
            activeModalType !== "answeredReviews" && (
              <button
                onClick={handleSubmitAnswers}
                className="w-full px-4 py-2 bg-gradient-to-b from-yellow-600 to-yellow-900 hover:from-cyan-600 hover:to-teal-700 text-white rounded-lg flex items-center justify-center gap-2 transition-all duration-200"
              >
                <Send className="w-4 h-4" />
                Submit Answers
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
