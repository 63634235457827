import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BarChart, Users, TrendingUp } from "lucide-react";

const Login = ({ onLoginSuccess }) => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Username cannot contain spaces");
    } else {
      setError("");
    }
    setUsername(value.replace(/\s/g, "").toLowerCase()); // Remove spaces and convert to lowercase
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (/\s/.test(value)) {
      setError("Password cannot contain spaces");
    } else {
      setError("");
    }
    setPassword(value.replace(/\s/g, "")); // Remove spaces
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Additional validation to ensure no empty fields after trimming
    if (!username.trim() || !password.trim()) {
      setError("Username and Password cannot be empty or contain only spaces.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/login",
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      localStorage.setItem("access_token", response.data.access_token);
      onLoginSuccess(response.data.user_info);
    } catch (err) {
      setError(
        err.response?.data?.detail ||
          "Login failed. Please check your credentials.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-neutral-100 to-neutral-300 relative overflow-x-hidden">
      <nav className="relative z-20 w-full mt-4 flex text-right ml-4 font-semibold p-4 bg-transparent">
        <div className="flex space-x-8">
          <a
            href="/about"
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            About Us
          </a>
          <a
            href="/contact"
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            Contact
          </a>
          <button
            onClick={() => setShowLoginForm(true)}
            className="text-neutral-800 hover:text-neutral-600 font-serif"
          >
            Login
          </button>
        </div>
      </nav>

      {/* Brand Logo */}
      <header className="p-8 flex flex-col items-center">
        <div className="flex items-end mt-16 md:mt-28 relative">
          <img src="/matcha9.svg" alt="Matcha Logo" className="h-full w-full" />
          <div className="absolute bottom-16 w-full h-0.5 bg-gradient-to-r from-transparent to-neutral-400"></div>
        </div>
      </header>

      {/* Mobile-only centered image */}
      <div className="block md:hidden w-full flex justify-center mt-8 mb-16">
        <img
          src="/image1.svg"
          alt="Matcha Logo"
          className="w-64 h-64 object-contain"
        />
      </div>

      {/* Desktop-only positioned image */}
      <div className="hidden md:block absolute top-0 right-0 w-1/4 h-screen">
        <img
          src="/image1.svg"
          alt="Matcha Logo"
          className="w-full h-full object-contain"
        />
      </div>

      <main className="container mx-auto px-4 py-8 font-serif relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h2 className="text-xl md:text-2xl font-serif font-semibold mb-4 text-gray-950">
              Ai-driven performance insights for teams that want to grow
              employees into leaders. Leave ScaryHR behind.
            </h2>
            <p className="text-xl md:text-lg mb-8 text-neutral-900">
              Lets get a Matcha
            </p>
            <div className="grid grid-cols-1 bg-dotted-pattern md:grid-cols-3 gap-6 mt-12">
              <div className="bg-gradient-to-l from-slate-700 to-neutral-950 bg-opacity-10 p-4 rounded-lg border border-neutral-300 shadow-2xl">
                <BarChart className="w-10 h-10 mb-2 text-neutral-100" />
                <h3 className="text-lg mb-2 text-neutral-100">
                  Employee Engagement
                </h3>
              </div>
              <div className="bg-gradient-to-l from-slate-700 to-neutral-950 bg-opacity-10 border border-neutral-300 p-4 rounded-lg shadow-2xl">
                <Users className="w-10 h-10 mb-2 text-neutral-100" />
                <h3 className="text-lg mb-2 text-neutral-100">
                  Talent Retention
                </h3>
              </div>
              <div className="bg-gradient-to-l from-slate-700 to-neutral-950 bg-opacity-10 p-4 rounded-lg border border-neutral-300 shadow-2xl">
                <TrendingUp className="w-10 h-10 mb-2 text-neutral-100" />
                <h3 className="text-lg text-neutral-100 mb-2">
                  Productivity Boost
                </h3>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Modal Login Form */}
      {showLoginForm && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ backdropFilter: "blur(8px)" }}
        >
          <div className="bg-gradient-to-t from-neutral-500 to-neutral-300 border border-neutral-300 shadow-2xl bg-opacity-90 p-8 rounded-lg w-11/12 md:w-1/3 relative">
            <button
              onClick={() => setShowLoginForm(false)}
              className="absolute top-4 right-4 text-[#4F5B32] text-2xl"
            >
              &times;
            </button>
            <h2 className="text-2xl font-serif text-neutral-900 font-semibold mb-6 text-center">
              Login to Your Account
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="username"
                  className="block mb-2 text-neutral-800"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={handleUsernameChange}
                  className="w-full px-3 py-2 text-neutral-100 bg-neutral-600 rounded focus:outline-none focus:ring-1 focus:ring-slate-100"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="text-neutral-800 block mb-2"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="w-full px-3 py-2 text-neutral-100 bg-neutral-600 rounded focus:outline-none focus:ring-1 focus:ring-slate-300"
                  required
                />
              </div>
              {error && <p className="text-red-300 mb-4">{error}</p>}
              <button
                type="submit"
                disabled={loading}
                className="w-full font-serif bg-gradient-to-b from-slate-800 to-neutral-950 text-neutral-100 hover:bg-slate-300 hover:text-neutral-400 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {loading ? "Logging in..." : "Submit"}
              </button>
            </form>
            <div className="mt-4 text-center">
              <Link
                to="/forgot-password"
                className="text-neutral-100 text-sm hover:text-sky-800"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
