import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PushReview from "./PushReview";

const QReviews = ({ businessName }) => {
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    fetchReviews();
  }, [businessName]);

  const fetchReviews = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/reviews`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setReviews(response.data);
    } catch (err) {
      console.error("Error fetching reviews:", err);
      setError("Failed to fetch reviews. Please try again.");
    }
  };

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  const handleCreateReview = () => {
    navigate(`/businesses/${businessName}/create-review`);
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      const token = localStorage.getItem("access_token");
      await axios.delete(
        `/api/v1/businesses/${businessName}/reviews/${reviewId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchReviews(); // Refresh the reviews list
    } catch (err) {
      console.error("Error deleting review:", err);
      setError("Failed to delete review. Please try again.");
    }
  };

  const displayedReviews = showAllReviews ? reviews : reviews.slice(-5);

  return (
    <div className="bg-gradient-to-b from-neutral-300 to-neutral-400 p-4 shadow-2xl mt-5">
      <h3 className="font-serif bg-gradient-to-b from-slate-700 to-neutral-950 border border-slate-900 text-lg text-center mb-8 text-neutral-100 p-1">
        {" "}
        Create Reviews
      </h3>

      <div className="flex flex-col gap-10">
        {/* Create New Review Button and PushReview are now side by side */}
        <div className="p-5 bg-gradient-to-br from-neutral-100 to-neutral-300 col-span-1 shadow-2xl">
          <h1 className="text-slate-900 mb-4 mt-4 font-serif font-sm">
            Create New Review
          </h1>
          <p className="text-slate-900 text-sm mb-2 font-serif">
            Want to create a new review? Click the button below. It will make a
            template and place it in "existing reviews" below. You could then
            select it from the "push review" section.{" "}
          </p>
          <button
            onClick={handleCreateReview}
            className="w-full h-1/4 mb-4 text-sm shadow-2xl font-serif py-2 bg-slate-600 text-neutral-100 hover:bg-neutral-600"
          >
            Create New Review
          </button>

          <PushReview businessName={businessName} />
        </div>

        {/* Existing Reviews Section */}
        <div className="bg-gradient-to-bl from-neutral-100 to-neutral-300 lg:col-span-2 border border-neutral-200 shadow-2xl p-6">
          <h4 className="text-md mb-3 text-slate-900 font-serif">
            Review Templates
          </h4>
          <p1 className="text-slate-800 text-sm">
            These are all of your review templates. Delete the ones you don't
            want. Create new ones with the button above!{" "}
          </p1>
          <div className="space-y-2 max-h-[calc(100vh-200px)] overflow-y-auto mt-2 shadow-lg">
            {displayedReviews.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                onReviewClick={handleReviewClick}
                onDeleteReview={handleDeleteReview}
              />
            ))}
          </div>
          {reviews.length > 5 && (
            <button
              onClick={() => setShowAllReviews(!showAllReviews)}
              className="mt-4 px-4 py-2 bg-slate-700 text-neutral-300 text-xs hover:bg-neutral-800"
            >
              {showAllReviews ? "Show Less" : `Show All (${reviews.length})`}
            </button>
          )}
        </div>
      </div>

      {error && <div className="mt-4 text-red-300">{error}</div>}

      {selectedReview && (
        <ReviewContent
          review={selectedReview}
          onClose={() => setSelectedReview(null)}
        />
      )}
    </div>
  );
};

const ReviewCard = ({ review, onReviewClick, onDeleteReview }) => {
  return (
    <div className="bg-slate-800 p-1 flex justify-between items-center">
      <div
        className="flex-grow ml-3 cursor-pointer mr-3 transition-colors p-2"
        onClick={() => onReviewClick(review)}
      >
        <h4 className="mb-2 text-neutral-100 text-sm font-serif">
          review {review.id}
        </h4>
        <p className="text-xs text-neutral-300 mt-1 font-serif">
          {review.reviews_text.substring(0, 100)}...
        </p>
      </div>
      <button
        onClick={() => onDeleteReview(review.id)}
        className="bg-neutral-300 text-slate-800 mr-2 text-xs px-3 py-1 hover:bg-neutral-800 transition-colors"
      >
        Delete
      </button>
    </div>
  );
};

const ReviewContent = ({ review, onClose }) => {
  return (
    <div className="fixed inset-0 bg-slate-950 bg-opacity-50 flex items-center justify-center">
      <div className="bg-gradient-to-bl from-slate-600 to-neutral-900 p-6 max-w-2xl w-full">
        <h2 className="text-lg font-serif mb-4 text-neutral-200">
          {review.reviews_text}
        </h2>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => {
          const question = review[`question_${num}`];
          if (question) {
            return (
              <div key={num} className="mb-4">
                <h3 className="text-neutral-400 mb-1 text-md">
                  Question {num}
                </h3>
                <p className="text-slate-200 font-serif">{question}</p>
              </div>
            );
          }
          return null;
        })}
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-stone-400 text-slate-100 text-xs rounded hover:bg-slate-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default QReviews;
