import React, { useState, useEffect } from "react";
import axios from "axios";
import { Users, UserPlus, Crown, Shield, Group, Check } from "lucide-react";

const Teams = ({ businessName }) => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, [businessName]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users. Please try again.");
    }
  };

  const fetchTeams = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `/api/v1/businesses/${businessName}/teams`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
      setError("Failed to fetch teams. Please try again.");
    }
  };

  const handleUserSelect = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  const handleCreateTeam = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setError("No authentication token found. Please log in again.");
        return;
      }

      await axios.post(
        `/api/v1/businesses/${businessName}/teams`,
        {
          team_name: teamName,
          member_ids: selectedUsers.map((id) => parseInt(id)),
          leader_id: parseInt(leaderId),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSuccessMessage("Team created successfully!");
      setTeamName("");
      setSelectedUsers([]);
      setLeaderId("");
      fetchTeams();
    } catch (error) {
      console.error("Error creating team:", error);
      setError(
        error.response?.data?.detail ||
          "Failed to create team. Please try again.",
      );
    }
  };

  // Helper function to get username by ID
  const getUsernameById = (userId) => {
    const user = users.find((u) => u.id === userId);
    return user ? user.username : "Unknown User";
  };

  return (
    <div className="min-h-screen bg-slate-900">
      <div className="max-w-6xl mx-auto px-6 py-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-serif font-semibold bg-gradient-to-r from-cyan-500 to-teal-900 bg-clip-text text-transparent">
            Team Management
          </h1>
          <Users className="w-8 h-8 text-indigo-400" />
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 p-4 bg-red-900/20 border border-red-800 rounded-lg text-red-400">
            {error}
          </div>
        )}

        {successMessage && (
          <div className="mb-6 p-4 bg-teal-900/20 border border-teal-800 rounded-lg text-teal-400 flex items-center gap-2">
            <Check className="w-5 h-5" />
            {successMessage}
          </div>
        )}

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column - Member Selection */}
          <div className="bg-slate-800 rounded-xl p-6 border border-slate-700">
            <h2 className="text-xl font-semibold mb-6 flex items-center gap-2">
              <UserPlus className="w-5 h-5 text-cyan-400" />
              Select Team Members
            </h2>
            <div className="space-y-3">
              {users.map((user) => (
                <div
                  key={user.id}
                  className="flex items-center p-3 bg-slate-900 rounded-lg border border-slate-700 hover:border-cyan-500 transition-colors duration-200"
                >
                  <input
                    type="checkbox"
                    id={`user-${user.id}`}
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleUserSelect(user.id)}
                    className="w-4 h-4 rounded border-slate-600 text-cyan-500 focus:ring-cyan-500"
                  />
                  <label
                    htmlFor={`user-${user.id}`}
                    className="ml-3 text-gray-300"
                  >
                    {user.username}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column - Team Creation */}
          <div className="bg-slate-800 rounded-xl p-6 border border-slate-700">
            <h2 className="text-xl font-semibold mb-6 flex items-center gap-2">
              <Group className="w-5 h-5 text-teal-400" />
              Create New Team
            </h2>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Team Name
                </label>
                <input
                  type="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="Enter team name"
                  className="w-full px-4 py-3 bg-slate-900 rounded-lg border border-slate-700 focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 transition-colors duration-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  Team Leader
                </label>
                <select
                  value={leaderId}
                  onChange={(e) => setLeaderId(e.target.value)}
                  className="w-full px-4 py-3 bg-slate-900 rounded-lg border border-slate-700 focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 transition-colors duration-200"
                >
                  <option value="">Select team leader</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.username}
                    </option>
                  ))}
                </select>
              </div>

              <button
                onClick={handleCreateTeam}
                className="w-full px-6 py-3 bg-gradient-to-r from-cyan-500 to-teal-600 hover:from-cyan-600 hover:to-teal-700 text-white rounded-lg font-medium flex items-center justify-center gap-2 transition-all duration-200"
              >
                <Shield className="w-4 h-4" />
                Create Team
              </button>
            </div>
          </div>
        </div>

        {/* Existing Teams Section */}
        {/* Existing Teams Section */}
        <div className="mt-8 bg-slate-800 rounded-xl p-6 border border-slate-700">
          <h2 className="text-xl font-semibold mb-6 flex items-center gap-2">
            <Users className="w-5 h-5 text-indigo-400" />
            Existing Teams
          </h2>
          {console.log("Teams data in render:", teams)}
          {console.log("Users data in render:", users)}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {teams.map((team) => (
              <div
                key={team.id}
                className="p-4 bg-slate-900 rounded-lg border border-slate-700"
              >
                <div className="flex items-center gap-2 mb-3">
                  <h3 className="text-lg font-semibold text-cyan-400">
                    {team.team_name}
                  </h3>
                </div>
                <div className="space-y-2 text-sm">
                  <div className="flex items-center gap-2 text-teal-400">
                    <Crown className="w-4 h-4" />
                    <span>
                      Leader:{" "}
                      {users.find((u) => u.id === team.leader_id)?.username ||
                        "Unknown"}
                    </span>
                  </div>
                  <div className="text-gray-400">
                    <span className="font-medium">Members: </span>
                    {team.member_ids
                      .map(
                        (memberId) =>
                          users.find((u) => u.id === memberId)?.username,
                      )
                      .filter(Boolean)
                      .join(", ")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
