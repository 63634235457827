import React from "react";
import { Users, Clock, Share2 } from "lucide-react";

const AnsweredReviewsSection = ({
  answeredReviews,
  showUserDropdown,
  setShowUserDropdown,
  selectedUser,
  handleUserSelect,
  handleRecordClick,
  handleReviewSelect,
  users,
}) => {
  return (
    <div className="bg-gradient-to-b from-neutral-100 to-neutral-300 rounded-lg p-4 border border-neutral-200 shadow-2xl backdrop-blur-sm">
      <h2 className="justify-center bg-gradient-to-b from-[#4f5b32] to-neutral-950 p-2 sm:text-sm rounded font-serif mb-3 text-neutral-200 flex items-center gap-2">
        <Users className="w-4 h-4 text-[#e7eee7] ml-2" />
        My Answered Reviews
      </h2>

      {answeredReviews.length === 0 ? (
        <p className="text-slate-400 font-serif text-center py-3">
          You haven't answered any reviews yet.
        </p>
      ) : (
        <div className="space-y-2 max-h-[30vh] overflow-y-auto custom-scrollbar pr-2">
          {answeredReviews.map((review) => (
            <div
              key={review.id}
              className="bg-gradient-to-b from-slate-800 to-neutral-950  p-3 rounded-md border border-neutral-600/50 shadow-md backdrop-blur-sm transition-all duration-200 cursor-pointer hover:border-neutral-100 group"
              onClick={() => handleReviewSelect(review, "answeredReviews")}
            >
              <div className="flex flex-col sm:flex-row justify-between items-start gap-3">
                <div className="space-y-1.5 flex-1">
                  <h3 className="text-sm sm:text-xs font-serif text-neutral-100 group-hover:text-slate-100">
                    {review.reviews_text}
                  </h3>
                  <p className="text-xs text-neutral-400 flex items-center gap-1.5 font-serif">
                    <Clock className="w-3 h-3" />
                    Answered on:{" "}
                    {new Date(review.expiration_date).toLocaleDateString()}
                  </p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowUserDropdown(review.id);
                  }}
                  className="px-2.5 py-1.5 bg-neutral-300 text-xs font-serif text-neutral-800 rounded-md border border-neutral-300/50 hover:bg-neutral-700/80 transition-colors duration-200 flex items-center gap-1.5 whitespace-nowrap"
                >
                  <Share2 className="w-3 h-3" />
                  Share
                </button>
              </div>

              {showUserDropdown === review.id && (
                <div
                  className="mt-3 space-y-2 pt-2 border-t border-neutral-700/50"
                  onClick={(e) => e.stopPropagation()}
                >
                  <select
                    onChange={(e) => handleUserSelect(e.target.value)}
                    value={selectedUser}
                    className="w-full px-2.5 py-1.5 bg-neutral-800/80 text-neutral-200 text-xs font-serif rounded-md border border-neutral-700/50 focus:border-cyan-500/50 focus:ring-1 focus:ring-cyan-500/50 transition-colors duration-200"
                  >
                    <option value="">Select a user to share with</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() => handleRecordClick(review.id)}
                    className="w-full px-2.5 py-1.5 bg-gradient-to-r from-cyan-500/90 to-teal-600/90 hover:from-cyan-500 hover:to-teal-600 text-white text-xs rounded-md flex items-center justify-center gap-1.5 transition-all duration-200 font-serif"
                  >
                    <Share2 className="w-3 h-3" />
                    Submit
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnsweredReviewsSection;
